import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Text, Cell } from 'recharts';





const CustomBarChart = ({data}) => {
  // Array of hex color codes from light to dark
const colorScale = ['#EFDBFF','#D3ADF7', '#B37FEB','#9254DE'];

// Function to determine color based on the value
const getBarColor = (value) => {
  const maxValue = 12; // Assuming the max value in your data is 12
  const colorIndex = Math.floor((value / maxValue) * (colorScale.length - 1));
  return colorScale[colorIndex];
};

const CustomLabel = ({ x, y, value }) => (
  <Text x={x} y={y} dy={-6} dx={16} fill="#7A7A7A" fontSize={14} textAnchor="middle">
    {value}
  </Text>
);
  return (
    <div style={{ width: '100%', backgroundColor: '#fff' }}>
      <ResponsiveContainer width="90%" height={128}>
        <BarChart data={data} margin={{ top: 20, right: 30, left: 50, bottom: 5 }}>
          <XAxis dataKey="date" axisLine={false} tickLine={false} />
          <YAxis hide />
          {/* <Tooltip /> */}
          <Bar dataKey="value" radius={[0, 0, 0, 0]} label={<CustomLabel />}>
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={getBarColor(entry.value)} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CustomBarChart;
