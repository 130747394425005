// import React, { useState } from 'react';
// import InputNumber from 'rc-input-number';
// import 'rc-input-number/assets/index.css';
// import Increment from "../../assets/Increment.svg"
// import Decrement from "../../assets/decrement.svg"
// import './NumberInput.css'; // Import your custom styles
// import "./createRequests.css"
// import { useVacancyStore } from './store/store';

// const NumberInputExample = ({title,min,max,width,inputVal}) => {

//     console.log(inputVal,"INPUTVAL")
//     const {viewFormModal,setViewFormModal} = useVacancyStore(state=>state)
//     const [value, setValue] = useState(inputVal?inputVal:0);

//     const handleChange = (newValue) => {
//         setValue(newValue);
//     };
//     const increment = () => setValue((prev) => Math.min(prev + 1, max)); // Adjust max if needed
//     const decrement = () => setValue((prev) => Math.max(prev - 1, min)); 
//     return (
//         <div className="input-group">
//             <label>{title}</label>
//             {/* <InputNumber 
//                 min={min} 
//                 max={max} 
//                 value={value} 
//                 step={1} 
//                 onChange={handleChange} 
//                 style={{ width: '100%' }}
//                 upHandler={<img src={Increment} alt="Increment" />}
//                 downHandler={<img src={Decrement} alt="Decrement"/>}
//             /> */}
//             {(viewFormModal===false)&&(<>
//             <div className='custom-number-input'>
//             <input
//             type='Number'
//             min={min} 
//             max={max} 
//             value={value} 
//             onChange={handleChange} 
//             />
//              <div className="buttons">
//                 <button onClick={increment} className="increment">
//                     <img src={Increment} alt="Increment" />
//                 </button>
               
//                 <button onClick={decrement} className="decrement">
//                     <img src={Decrement} alt="Decrement" />
//                 </button>
//             </div>
//             </div></>)}
//             {(viewFormModal===true)&&(
//                 <div style={{color:'#1f1f1f'}}>
//                     {inputVal!==undefined?inputVal:'-'}
//                 </div>
//             )

//             }
//         </div>
//     );
// };

// export default NumberInputExample;
import React, { useEffect, useState } from 'react';
import Increment from "../../assets/Increment.svg";
import Decrement from "../../assets/decrement.svg";
import './NumberInput.css'; // Import your custom styles
import "./createRequests.css";
import { useVacancyStore } from './store/store';

const NumberInputExample = ({ title, min, max, width, inputVal, onChange}) => {
    const { viewFormModal } = useVacancyStore(state => state);
    const [value, setValue] = useState(inputVal|| 0);

    useEffect(() => {
        setValue(inputVal || 0);
    }, [inputVal]);

    const handleChange = (e) => {
        e.preventDefault();
        const newValue = parseInt(e.target.value, 10); // Parse the value from input
        setValue(newValue);
        onChange(newValue); // Pass the new value to the parent component
    };

    const increment = (e) => {
        e.preventDefault();
        const newValue = Math.min(value + 1, max);
        setValue(newValue);
        onChange(newValue); // Call onChange with new value
    };

    const decrement = (e) => {
        e.preventDefault();
        const newValue = Math.max(value - 1, min);
        setValue(newValue);
        onChange(newValue); // Call onChange with new value
    };

    return (
        <div className="input-group">
            <label>{title}</label>
            {viewFormModal === false && (
                <>
                    <div className='custom-number-input'>
                        <input
                            type='Number'
                            min={min} 
                            max={max} 
                            value={value} 
                            onChange={(e)=>{handleChange(e)}} 
                            
                        />
                        <div className="buttons">
                            <button onClick={(e)=>{increment(e)}} className="increment">
                                <img src={Increment} alt="Increment" />
                            </button>
                            <button onClick={(e)=>{decrement(e)}} className="decrement">
                                <img src={Decrement} alt="Decrement" />
                            </button>
                        </div>
                    </div>
                </>
            )}
            {viewFormModal === true && (
                <div style={{color:'#1f1f1f'}}>
                    {inputVal !== undefined ? inputVal : '-'}
                </div>
            )}
        </div>
    );
};

export default NumberInputExample;
