import React, { useEffect, useState } from "react";
import Api from "../service/Api"
export const useGet = (endUrl,params,)=>{
    const [data,setData]=useState()
    const [isLoading,setIsLoading]=useState()
    const [error,setError]=useState()

    useEffect(() =>{
        setIsLoading(true)
    Api.get(endUrl).params(params)
    .send((res,err) =>{
        setIsLoading(false)
      
        if(err){
            setError(err)
            
        }
        else if(res){
          setData(res)  
        }
    
    })
    
    },[endUrl])

    return {data,error,isLoading}
}

