
import './homeStyles.css';

const Header=(props)=> {

  const {color} = props??[];
  
  return (
    <div className="landing_header">
    <div className='landing_logo' >
        <a href='/'  style={{color: `${color}`}}>whiteink.ai</a>
    </div>

    </div>
  );
}

export default Header;