import { useEffect, useState,useRef } from "react"
import './styles.css'
import Divider from "../../../../components/divider/Divider"
import StatusBar from "../../jobs/StatusBar"
import remainder from '../../../../assets/icons/remainder.svg'
import { CheckOutlined, CloseOutlined, CopyOutlined, DownloadOutlined, DownOutlined, EditOutlined, LoadingOutlined, PlusCircleOutlined } from "@ant-design/icons"
import { Button, Dropdown, Input, message, Modal, Spin } from "antd"
import JobIndustrySelector from "../../../hiring/jobIndustrySelector"
import CustomButton from "../../../hiring/customButton"
import Generate from "../../../../assets/generateIcon.svg"
import CustomModal from "../../../hiring/customModal"
import { useStore } from "../../../hiring/store/store"
import parse from 'html-react-parser';
import EditorToolbar, { modules, formats } from "../../../../components/EditorToolbar/EditorToolbar";
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css';
import jobInfo from '../../../../assets/icons/job-basic.svg'
import jobRequirement from '../../../../assets/icons/job-requirement.svg'
import jobCompensation from '../../../../assets/icons/job-compensation.svg'
import addJd from '../../../../assets/icons/add-jd.svg'
import addKeywords from '../../../../assets/icons/add-keyword.svg'
import jdIcon from '../../../../assets/icons/jd-icon.svg'
import jobKeywords from '../../../../assets/icons/job-keywords.svg'
import { generateJd, getJobOverview, updateVacancyRequests } from "../../Action/action"
import { useParams } from 'react-router-dom';
import { useVacancyStore } from "../../store/store"
import JobOverviewEditModal from "./editModal"

const JobOverview=()=>{
 
  
    const [show, setShow] = useState(false);
    const [loadMore,setLoadMore]=useState(false);
    const [mandatoryKeywordModal,setMandatoryKeywordModal]=useState(false);
    const [optionalKeywordModal,setOptionalKeywordModal]=useState(false);
    const [ mandatoryKeywords,setMandatoryKeywords]=useState([]);
   
    const [optionalKeywords,setOptionalKeywords]=useState([]);
    const [newMandatoryKeyword,setNewMandatoryKeyword]=useState();
    const [newOptionalKeyword,setNewOptionalKeyword]=useState();
    const [editModal,setEditModal]=useState(false);
    const [jdModal,setJdModal]=useState(false);
    const [jdLoading,setjdLoding]=useState(false);
    const { id } = useParams();

    const [keywordsLoding,setKeywordsLoding]=useState(false);

  
    const handleCopy=()=>{
        const pTag = document.createElement('p');
         pTag.innerHTML =jdData;
        navigator.clipboard.writeText(pTag.innerText)
        .then(() => {
          // alert('Content copied to clipboard!');
          message.success("Text copied to clipboard.")
        })
        .catch(err => {
          // console.error('Failed to copy: ', err);
        });
        
    }
    const {titleJob, titleIndustry,isModalOpen,setIsModalOpen,jdData,
      setJdData,jobExperience,progress,setProgress,
       showExperince,setShowExperince,setTitleJob,setMandatorySkills,setOptionalSkills} = useStore(state=>state);

 const { setVacancyData,vacancyData}=useVacancyStore(state=>state);


    useEffect(()=>{
    
    setIsModalOpen(false);
    const params={
      jobId:id
    }
    fetchJobData(params);

    },[])



    const fetchJobData=(params)=>{
      getJobOverview(params,(response,error)=>{
        if (error) {
          console.error("Error posting vacancy:", error);
        } else {
        
          setVacancyData(response?.jobs?.[0]);
          setJdData(response?.jobs?.[0]?.jobDescription);
          setTitleJob(response?.jobs?.[0]?.jobTitle);
          setMandatoryKeywords(response?.jobs?.[0]?.mandatoryKeywords??[])
          setOptionalKeywords(response?.jobs?.[0]?.optionalKeywords??[]);
          setMandatorySkills(response?.jobs?.[0]?.mandatoryKeywords?.join(','))
          setOptionalSkills(response?.jobs?.[0]?.optionalKeywords?.join(','))
        }
      })
    }


    const updateStatus=(status)=>{
     
      let params={status, jobId:id};
      updateVacancyRequests(params,()=>{
        const params={
          jobId:id
        }
        fetchJobData(params);
  
      })
    }

   const updateMandatoryKeywords=()=>{
    //api call
    if(mandatoryKeywords.length==0){
      message.warning("Please input mandatory skills")
      return;
    }
    setKeywordsLoding(true);
    let params={mandatoryKeywords:mandatoryKeywords?.join(','), jobId:id};
    updateVacancyRequests(params,()=>{
      const params={
        jobId:id
      }
      fetchJobData(params);
      setKeywordsLoding(false);
      setMandatoryKeywordModal(false);

    })
   }
   const updateOptionalKeywords=()=>{
    //api call
    if(optionalKeywords.length==0){
      message.warning("Please input optional skills")
      return;
    }
    setKeywordsLoding(true);
    let params={optionalKeywords:optionalKeywords?.join(','), jobId:id};
    updateVacancyRequests(params,()=>{
      const params={
        jobId:id
      }
      fetchJobData(params);
      setKeywordsLoding(false);
      setOptionalKeywordModal(false);

    })
   }

   const postData=()=>{
    //api call
    if(titleJob===''){
        message.warning("Please input job title ")
        return;
    }

    setShowExperince(true);
    setIsModalOpen(true);
  
    // setIsLoading(true);

   }

   const updateJd=()=>{

    setjdLoding(true);

    //condition check

    let params={ jobDescription:jdData, jobId:id};
    updateVacancyRequests(params,()=>{
      const params={
        jobId:id
      }
      fetchJobData(params);
      setjdLoding(false);
      setJdModal(false)

    })
   
    //api calll
   }
  
   const generateJdModal=()=>{
// fetch jd api call with title and industry and experinece
setShowExperince(true);
setIsModalOpen(true);
setProgress(0);
// const payload = {
//   "role":vacancyData?.jobTitle,
//   "must_have_skills":"",
//   "good_to_have_skills": "",
//   "years_of_experience": vacancyData?.minExperience+"-"+vacancyData?.maxExperience+" years",
//   "degree_requirements": "",
//   "industry":vacancyData?.industyExperince,
//   "jobDescription":jdData
// };

// generateJd(payload,(response,error)=>{
//   console.log("ai generated jd ",response
//   )
//   setJdData(response?.response);
//   setProgress(100)
//   setIsModalOpen(true);
// })



   }
    const addMandatoryKeyword=()=>{
        if(newMandatoryKeyword){
        setMandatoryKeywords( [newMandatoryKeyword,...mandatoryKeywords])
        setNewMandatoryKeyword();
        }
    }
    const addOptionalKeyword=()=>{
      if(newOptionalKeyword){
      setOptionalKeywords( [newOptionalKeyword,...optionalKeywords])
      setNewOptionalKeyword();
      }
  }
const removeMandatoryKeyword=(keyword)=>{
    setMandatoryKeywords( mandatoryKeywords?.filter(v => v !== keyword))
}
const removeOptionalKeyword=(keyword)=>{
  setOptionalKeywords( optionalKeywords?.filter(v => v !== keyword))
}

const handleCloseModal = () =>{
    setIsModalOpen(false)
}
const handleChange=(value)=>{
    console.log('jd-text',value)
    const regex = /<p>[^<]*<br>\s*<\/p>/g;

    //  if(!regex.test(value))
    //   setJdData(value)
    // else
    // setJdData("")

    if(value=='<p><br><\/p>'){
      setJdData("")
    }else{
      setJdData(value)
    }
}



    const statusBtnColor=(status)=>{
     
        switch(status){
            case "Closed":return "#F5222D";
            case "On-hold":return "#FA8C16";
            case "Active":return "#52C41A"
            default: return "#E9E9E9";

        }
    }
 
    const statusList=[
        {
            key:'Active',
            label:<>Active &nbsp; {vacancyData?.status=='Active'?<CheckOutlined style={{color:'#1890FF'}}/>:null}</>
        },
        {
            key:'On-hold',
          
            label: <>On-hold  &nbsp; {vacancyData?.status=='On-hold'?<CheckOutlined style={{color:'#1890FF'}}/>:null}</>
              
          },
        {
            key:'Closed',
           
            label: <>Close  &nbsp; {vacancyData?.status=='Closed'?<CheckOutlined style={{color:'#1890FF'}}/>:null}</>
        }
      
        ]

 

  const [isOverflow, setIsOverflow] = useState(false);

  const generateJdRef = useRef(null);
        useEffect(() => {
            // Check if height exceeds 700px
            if (generateJdRef.current && generateJdRef.current.scrollHeight > 700) {
              setIsOverflow(true);
            }
          });
    return(
        <>
        <div className="job-overview">
         <div className="section-1">
     
       <div className="job-status">
       <div className="jobcard-header">
        <div>
        <h3 className="jobcard-title">Status</h3>
        <span className="jobcard-field">The job is open, and hiring efforts are in motion.</span>
        </div>
       
        <Dropdown
        menu={{
            items:statusList,
            defaultSelectedKeys: [vacancyData?.status],
        onClick:({key})=>{
    
         updateStatus(key);
        }
        }}
        trigger={['click']}
        placement="bottomRight"
        style={{padding:'0'}}
   
    
      >
            <button style={{background:statusBtnColor(vacancyData?.status)}} className="joboverview-status"><p style={{width:'max-content'}}>{vacancyData?.status?.toUpperCase()??'-'}</p> <DownOutlined/></button>
     
        </Dropdown>

       
        </div>
        {/* <Divider/>
        <div>
            <h3>Stages</h3>
            <p></p>
            <div className="jobcard-status">
            <StatusBar percent={vacancyData?.percentage??0} bars={vacancyData?.template?.stage?.map(e=>e?.stageKey)} stage={vacancyData?.stage}/>
          

        </div>
        </div> */}
        <div className="job-status-remainder"> 
        <img src={ remainder }></img>
        <p>0 days remaining to secure the ideal candidate.</p>

        </div>
      </div>
      
      <div className="job-info">
      <div className="job-info-header-1">
                <div className="job-info-title">
                <img src={jobInfo}></img>
                <div>Basic Job Information</div>
                </div>
                <div className="job-info-edit" onClick={()=>{
                  setEditModal(true)
                }}>
                  <EditOutlined style={{ color: "#1890FF"}} />
                   <div> Edit</div>
                </div>
                  
        </div>
        <div className="job-info-data">
          <div className="data-field">
           <div className="data-name">Job title</div>
           <div className="data-value">{vacancyData?.jobTitle??'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Job Code</div>
           <div className="data-value">{vacancyData?.jobCode??'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Job Type</div>
           <div className="data-value">{vacancyData?.jobType??'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Department</div>
           <div className="data-value">{vacancyData?.department??'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Team</div>
           <div className="data-value">{vacancyData?.team??'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Job location</div>
           <div className="data-value">{vacancyData?.jobLocation??'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Work arrangements</div>
           <div className="data-value">{vacancyData?.workArrangements??'-'}</div>
          </div>
        </div>
        <Divider/>
        <div className="job-info-header-2">
                <div className="job-info-title">
                <img src={jobRequirement}></img>
                <div>Job Requirements</div>
                </div>
               
                  
        </div>
        <div className="job-info-data">
          <div className="data-field">
           <div className="data-name">Qualifications</div>
           <div className="data-value">{vacancyData?.qualifications??'-'}</div>
          </div>
         
          <div className="data-field">
           <div className="data-name"> Specializations</div>
           <div className="data-value">{vacancyData?.specializations??'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Certifications</div>
           <div className="data-value">{vacancyData?.certification??'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Industry-specific experience</div>
           <div className="data-value">{vacancyData?.industyExperince??'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Years of experience</div>

           {vacancyData?.minExperience || vacancyData?.maxExperience?
            <div className="data-value"> {vacancyData?.minExperience??'-'}-{vacancyData?.maxExperience??'-'} years</div>:
            <div className="data-value">-</div>}
          </div>
          <div className="data-field">
           <div className="data-name">Preferred notice period (In days)</div>
           <div className="data-value">{vacancyData?.noticePeriod??'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Travel involvement</div>
           <div className="data-value">{vacancyData?.travel??'-'}</div>
          </div>
          </div>
        <Divider/>
        <div className="job-info-header-3">
        
                <div className="job-info-title">
                <img src={jobCompensation}></img>
                <div>Compensation</div>
                </div>
               
                  
        </div>

        <div className="job-info-data">
      
        <div className="data-field">
           <div className="data-name">  Currency</div>
           <div className="data-value">{vacancyData?.currency??'-'}</div>
          </div>
         
          <div className="data-field">
           <div className="data-name"> Compensation structure</div>
           <div className="data-value">{vacancyData?.compensationStructure??'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Range</div>
           {vacancyData?.minCompensation || vacancyData?.maxCompensation?
            <div className="data-value"> {vacancyData?.minCompensation??'-'}-{vacancyData?.maxCompensation??'-'}</div>:
            <div className="data-value">-</div>}
          </div>
          </div>
      </div>
          
         </div>
         <div className="section-2">
            <div className="job-description">
            <div className="jd-header">
                <div className="jd-title">
                <img src={jdIcon}></img>
                <div>Job Description</div>
                </div>
                <div className="jd-actions">

               
                <div className="jd-edit" onClick={()=>{
                    handleCopy()
                }}>
                  <CopyOutlined style={{ color: "#FA8C16"}} />
                   <div style={{color:"#FA8C16"}}> Copy text</div>
                </div>
                <div className="jd-edit" onClick={()=>{
                   
                }}>
                  <DownloadOutlined style={{ color: "#52C41A"}} />
                   <div style={{color:'#52C41A'}}> Download</div>
                </div>
                <div className="jd-edit" onClick={()=>{
                        setTimeout(()=>{
                            setShow(true);
                        }, 100);
                       setJdModal(true)
                }}>
                  <EditOutlined style={{ color: "#1890FF"}} />
                   <div> Edit</div>
                </div>
                </div>
                </div>
              {jdData?
                <div
                className="generateJd"
                ref={generateJdRef}
                style={{
                height: loadMore ? "max-content" : "600px",
                width:'100%',
                overflow: "hidden",
                position:'relative'
                }}
                >
                {parse(jdData)}
                {isOverflow && !loadMore && (
                <div className="load-more">
                    <div onClick={() => setLoadMore(true)}>
                    <PlusCircleOutlined style={{ color: "#1890FF" }} /> Load More
                    </div>
                </div>
                )}
                </div>
              :
              <div className="add-jd" style={{padding:0}}>
                <div className="generateJd">
                <img src={addJd}></img>
                    <h3>Add Job Description</h3>
                    <p>AI-powered job descriptions at your fingertips—just click and go!</p>

                    <div style={{display:'flex', flexDirection:'row',gap:'1rem', alignItems:'center',justifyContent:'center',flexWrap:'wrap',}}>
                 <JobIndustrySelector/>
                 <CustomButton text="Generate" img={Generate} onClick={postData}/>
                </div>
                </div>
                
             <div className="skip-ai">
               <p>Would you like to manually input the job description?</p>
               <span onClick={()=>{
                   setTimeout(()=>{
                            setShow(true);
                        }, 100);
                       setJdModal(true)
               }}>Skip AI</span>
                </div>
                </div>
              }
              
               
               



            </div>
            <div className="job-keywords">

                <div className="keywords-header">
                <div className="keywords-title">
                <img src={jobKeywords}></img>
                <div>keywords</div>
                </div>
            
                  
                </div>

                <div className="keywords-header">
                <div  className="keywords-title" style={{marginLeft:8,width:"100%",display:"flex",flexDirection:"row",color:'#1F1F1F',fontSize:'16px',fontWeight:600}}><div style={{height:20,width:4,backgroundColor:"#2337C6",marginRight:4}}></div>Mandatory Skills</div>

                <div className="keywords-edit" onClick={()=>{
                    setMandatoryKeywordModal(true)
                }}>
                  <EditOutlined style={{ color: "#1890FF"}} />
                   <div> Edit</div>
                </div>
                </div>
               { mandatoryKeywords?.length>0?
                <div className="keywords-list">
                {mandatoryKeywords?.map(keyword=>{
                    return <div>{keyword}</div>
                  })}
                </div>:
                <div className="keywords-nodata" >
                    <img src={addKeywords}></img>
                    <h3>No keywords yet!</h3>
                    <p>Add specific keywords to improve search accuracy</p>
                    <button  onClick={()=>{
                    setMandatoryKeywordModal(true)
                }}><PlusCircleOutlined/> Add keywords</button>
                </div>
               }

               <div className="keywords-header">
                <div  className="keywords-title" style={{marginLeft:8,width:"100%",display:"flex",flexDirection:"row",color:'#1F1F1F',fontSize:'16px',fontWeight:600}}><div style={{height:20,width:4,backgroundColor:"#2337C6",marginRight:4}}></div>Optional Skills</div>

                <div className="keywords-edit" onClick={()=>{
                    setOptionalKeywordModal(true)
                }}>
                  <EditOutlined style={{ color: "#1890FF"}} />
                   <div> Edit</div>
                </div>
                </div>
              
                { optionalKeywords?.length>0?
                <div className="keywords-list">
                  { optionalKeywords?.map(keyword=>{
                    return <div>{keyword}</div>
                  })}
                </div>:
                <div className="keywords-nodata" >
                    <img src={addKeywords}></img>
                    <h3>No keywords yet!</h3>
                    <p>Add specific keywords to improve search accuracy</p>
                    <button  onClick={()=>{
                    setOptionalKeywordModal(true)
                }}><PlusCircleOutlined/> Add keywords</button>
                </div>
               }
            </div>
         </div>
       
        </div>

        <Modal
        title={<p className="keyword-modal-title">Mandatory Skills</p>}
        footer={
        !keywordsLoding?  <button onClick={updateMandatoryKeywords} className="keyword-modal-done">
            Done 
          </button>:
          <>
            <button className="keyword-modal-done" style={{color:'rgb(255,255,255,0.7',background:'#4D4D4D'}}> Done  <Spin indicator={<LoadingOutlined spin />} size="small" /></button>
          </>

        }
       
        open={mandatoryKeywordModal}
        onCancel={() => setMandatoryKeywordModal(false)}
      >
        <div className="keyword-input-wrapper">
            <div className="keyword-input">
            <Input placeholder="Enter keyword"  className="keyword-input-field" value={newMandatoryKeyword} onChange={(e)=>{setNewMandatoryKeyword(e.target.value)}}/>
            <button  onClick={addMandatoryKeyword}><PlusCircleOutlined/> Add</button>
            </div>
            { mandatoryKeywords?.length>0?
            <div className="keywords-list">
            {mandatoryKeywords?.map(keyword=>{
                    return <div>{keyword} <CloseOutlined onClick={()=>removeMandatoryKeyword(keyword)}/></div>
                  })}
            </div>
           :
            <div className="keywords-nodata" style={{width:'100%',height:380,padding:'0px'}}>
                    <img src={addKeywords}></img>
                    <h3>No keywords yet!</h3>
                    <p>Add specific keywords to improve search accuracy</p>
              
                </div>
            }
        </div>
      

    
      </Modal>
      <Modal
        title={<p className="keyword-modal-title">Optional Skills</p>}
        footer={
        !keywordsLoding?  <button onClick={updateOptionalKeywords} className="keyword-modal-done">
            Done 
          </button>:
          <>
            <button className="keyword-modal-done" style={{color:'rgb(255,255,255,0.7',background:'#4D4D4D'}}> Done  <Spin indicator={<LoadingOutlined spin />} size="small" /></button>
          </>

        }
       
        open={optionalKeywordModal}
        onCancel={() => setOptionalKeywordModal(false)}
      >
        <div className="keyword-input-wrapper">
            <div className="keyword-input">
            <Input placeholder="Enter keyword"  className="keyword-input-field" value={newOptionalKeyword} onChange={(e)=>{setNewOptionalKeyword(e.target.value)}}/>
            <button  onClick={addOptionalKeyword}><PlusCircleOutlined/> Add</button>
            </div>
            { optionalKeywords?.length>0?
            <div className="keywords-list">
            {optionalKeywords?.map(keyword=>{
                    return <div>{keyword} <CloseOutlined onClick={()=>removeOptionalKeyword(keyword)}/></div>
                  })}
            </div>
           :
            <div className="keywords-nodata" style={{width:'100%',height:380,padding:'0px'}}>
                    <img src={addKeywords}></img>
                    <h3>No keywords yet!</h3>
                    <p>Add specific keywords to improve search accuracy</p>
              
                </div>
            }
        </div>
      

    
      </Modal>

      <Modal
        title={<p className="keyword-modal-title">Job Description Editor</p>}
        footer={
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',width:'100%'}} >
            <div style={{width:'max-content'}}>
            <CustomButton text={jdData?"Regenerate with AI":"Generate with AI"} img={Generate} onClick={generateJdModal}/>
            </div>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',gap:'10px'}}>
            <button onClick={()=>{setJdModal(false)}} className="keyword-modal-done">
          Cancel
          </button>
           {!jdLoading? <button onClick={updateJd} className="keyword-modal-done" style={{background:'#1890FF',color:'white',border:'none'}}>
            Save Data 
          </button>:<button className="keyword-modal-done" style={{background:'#1890FF',color:'white',border:'none'}}>
            Save Data  <Spin indicator={<LoadingOutlined spin  style={{color:'white'}}/>} size="small" />
          </button>}
    
            </div>
        
            </div>
         
        }
       
        open={jdModal}
        onCancel={() => setJdModal(false)}
        width={'80vw'}
        centered
      >
      <div className="jd-modal">
  
      { show?
         <>
         <EditorToolbar/>
         <ReactQuill
            theme="snow"
            value={jdData}
            onChange={handleChange}
            placeholder={"Write here..."}
            modules={modules}
            formats={formats}
            className="quill-editor"
              
          />
        
        
         </>
          :<>
          <h3 class="card-title animated-bg animated-bg-text" id="title">
                &nbsp;
            </h3>
               <h3 class="card-title animated-bg animated-bg-text" id="title" style={{height:'20px'}}>
                &nbsp;
            </h3>
            <p class="card-excerpt" id="excerpt">
                &nbsp;
                <span class="animated-bg animated-bg-text">&nbsp;</span>
                <span class="animated-bg animated-bg-text">&nbsp;</span>
                <span class="animated-bg animated-bg-text">&nbsp;</span>
            </p>
            <h3 class="card-title animated-bg animated-bg-text" id="title" style={{height:'20px'}}>
                &nbsp;
            </h3>
            <p class="card-excerpt" id="excerpt">
                &nbsp;
                <span class="animated-bg animated-bg-text">&nbsp;</span>
                <span class="animated-bg animated-bg-text">&nbsp;</span>
                <span class="animated-bg animated-bg-text">&nbsp;</span>
            </p>
          </>
        
            }
            </div>
      </Modal>

      {isModalOpen && (<CustomModal show={isModalOpen} handleClose={handleCloseModal} showExperince={showExperince} />)}

      {editModal && <JobOverviewEditModal editModal={editModal} setEditModal={setEditModal} data={vacancyData}     fetchJobData={fetchJobData}/>}
        </>

       
        
    )
}
export default JobOverview