import React from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import "./createRequests.css"

const MyDatePicker = ({date,handleAnticipatedDate}) => {
  console.log(dayjs(date,"DD/MM/YYYY"),"DATE123")
  const disablePastDates = (current) => {
    // Disable dates before today
    console.log(current,"DATE123")
    return current && current < dayjs().startOf('day');
  };
  const handleDateChange = (value)=>{
    handleAnticipatedDate(value)
  }

  return(<DatePicker 
                    disabledDate={disablePastDates} 
                    defaultValue={date? dayjs(date,"YYYY/MM/DD"):null} 
                     placeholder="Select Date"
                   
                     className="requests-datepicker" 
                     onChange={handleDateChange}
        />);
};

export default MyDatePicker;
