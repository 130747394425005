// NotificationsComponent.js

import React, { useEffect,useRef,useState } from 'react';
import backendUrl from '../../service/credentials';
import Notification from '../../service/Notification';
import './style.css'
import notifIcon from '../../assets/notifIcon.svg'
import { CloseOutlined, LoadingOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { getNotifications, getNotificationsAlertsCount, notificationAction, updateNotificationsAlertsCount } from './action/action';
import { useNotificationStore } from './store/store';
import { timeAgo } from '../vacancies/jobs/JobCard';
import { Spin } from 'antd';
import no_job from '../../assets/icons/no-job.svg'
const NotificationsUtil = () => {
  const sseConnectionRef = useRef(null);
  const [notifications, setNotifications] = useState([]);
 const [loading,setLoading]=useState(false);
 const [loadMore,setLoadMore]=useState(false);
  const[openNotificationBar,setOpenNotificationBar]=useState();
 const {notificationRes, setNotificationRes}=useNotificationStore(store=>store);
 const [count,setCount]=useState();
  const openNotifications=()=>{
    setOpenNotificationBar(true);
    updateNotificationsAlertsCount({userId:4},(res,err)=>{
      if(res)
      setCount()
    })

    getAllNotifications();
  }
  const closeNotifications=()=>{
    setOpenNotificationBar(false);
  }

  const connectSSE = (retryCount = 0) => {
    if (sseConnectionRef.current) {
      // Connection already exists, no need to create a new one
      return;
    }

    const sse = new EventSource(`${backendUrl}/api/hiring/notifications?userId=4`);
    sseConnectionRef.current = sse;

    sse.onmessage = (event) => {
      // Handle incoming messages
      console.log("New notification:", event.data);
      const data = JSON.parse(event.data);
      setNotifications([data]);
    };

    sse.onerror = () => {
      console.error("SSE connection error, retrying...");
      sse.close();
      sseConnectionRef.current = null; // Reset the ref on error

      // Retry connection with exponential backoff, capped at 30 seconds
      const retryTimeout = Math.min(30000, Math.pow(2, retryCount) * 1000);
      setTimeout(() => connectSSE(retryCount + 1), retryTimeout);
    };
  };

  useEffect(() => {
    // Establish the SSE connection once when component mounts
    connectSSE(); // Initialize the connection

    // Clean up the connection when component unmounts
    return () => {
      if (sseConnectionRef.current) {
        sseConnectionRef.current.close();
        sseConnectionRef.current = null; // Reset ref on cleanup
      }
    };
  }, []);

  // useEffect(() => {
  //   // Check if an SSE connection already exists
  //   if (!sseConnectionRef.current) {
  //     console.log("Notification sse connected")
  //     // Establish SSE connection only once
  //     const sse = new EventSource(backendUrl+'/api/hiring/notifications?userId=3');
  //     sseConnectionRef.current = sse;

  //     sse.onmessage = (event) => {
  //       // Handle incoming messages
  //       console.log("New notification:", event.data);
  //       const data = JSON.parse(event.data);
  //       setNotifications([data]);
  //     };

  //     sse.onerror = () => {
  //       sse.close();
  //       sseConnectionRef.current = null; // Reset the ref on error
  //     };
  //   }

  //   // Clean up the connection when component unmounts
  //   // return () => {
  //   //   if (sseConnectionRef.current) {
  //   //     sseConnectionRef.current.close();
  //   //   }
  //   // };
  // }, []);

  useEffect(()=>{
    console.log("notifi",notifications)
    if(!openNotificationBar){
    notifications.map((ele, index) => {

    Notification.info(ele?.notification?.title,<NotificationCard ele={ele?.notification}/>);
    });
  }
  getNotificationsAlertsCount({userId:4},(res,err)=>{
    if(res)
    setCount(res?.count)
  })
    getAllNotifications();
  },[notifications])


  const getAllNotifications=()=>{
    let params={
      userId:4,
      limit:10,
      page:1,
    
    }
    setLoading(true);
    getNotifications(params,(response,error)=>{
       setNotificationRes(response)
       setLoading(false);
    })
  }
  
  
const loadMoreHandler=()=>{
  let params={
    userId:4,
    limit:10,
    page: (notificationRes?.page||0)+1,
  
  }
  setLoading(true);
  getNotifications(params,(response,error)=>{
     setNotificationRes({
      notifications:[...notificationRes?.notifications,...response?.notifications],
      page:response?.page,
      loadMore:response?.loadMore})
     setLoading(false);
  })
}
  
const getInitials=(name)=> {
  const words = name.trim().split(" ");
  if (words.length === 1) {
    return words[0].slice(0, 2).toUpperCase();
  } else {
    return (words[0][0] + words[words.length - 1][0]).toUpperCase();
  }
}

const stringToColor=(name)=> {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Convert hash to hexadecimal color
  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xFF;
    color += ('00' + value.toString(16)).slice(-2);
  }

  return color;
}

const  adjustColorBrightness=(hex, percent)=> {
  let num = parseInt(hex.slice(1), 16); // Remove "#" and parse the hex
  let r = (num >> 16) + percent; // Adjust red channel
  let g = ((num >> 8) & 0x00FF) + percent; // Adjust green channel
  let b = (num & 0x0000FF) + percent; // Adjust blue channel

  r = Math.max(0, Math.min(255, r)); // Clamp value between 0-255
  g = Math.max(0, Math.min(255, g));
  b = Math.max(0, Math.min(255, b));

  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`; // Convert back to hex
}


  return (

    <>

<section class="banner">
<div style={{display:'flex',justifyContent:'center',alignItems:'center',position:'relative',cursor:'pointer'}} onClick={openNotifications}>
<img src={notifIcon} alt="NotificationIcon"  style={{width:'20px',height:'20px',cursor:'pointer'}} >

</img>
{
  count?<span className='notification-count'>{count}</span>:null
}

</div>


  <div class={`sidebar ${openNotificationBar?'active-notification-siderbar':''}`}>
  <div className='sidebar__header'>
  <CloseOutlined onClick={closeNotifications} for="menu-control" className="sidebar__close" />
  <h3 className='sidebar__heading'>Notifications</h3>
  </div>

<div className='sidebar__body'>
{notificationRes?.notifications?.map(ele=>{
  let obj={};
try{
obj=JSON.parse(ele.info);

}catch(e){
  obj={}
}


  return <div className='sidebar__notification__card' style={{background: ele?.seenStatus==false?'#E6F7FF':"white"}}>
<div className='notification__card__header'>
    <div className='notification__card__profile' style={{backgroundColor:adjustColorBrightness(stringToColor(ele?.user?.name||'Praveen Kumar'),70),color:adjustColorBrightness(stringToColor(ele?.user?.name||'Praveen Kumar'),-70)}}>{getInitials(ele?.user?.name||'Praveen')}</div>
    <div className='notification__card__content'>
          <div className='notification__card__title'>{ele?.message} </div>
          <div className='notification__card__date'>{timeAgo(ele?.updatedAt)}</div>

    </div>

</div>


{
 
  obj?.action==true?
  
  <div className='notification__card__actions'>
  {
     obj?.buttons?.map(btn=>{
    
     if(btn?.type=='ok'){
      {console.log("hii",btn,btn?.type)}
      return <button className='notification-ok'  onClick={()=>{
      if( obj?.actionType && obj?.endpoint){
        notificationAction(
          obj?.actionType,
           obj?.endpoint,
           btn?.payload,((response,error)=>{
              console.log("error",error,response)
              if(response){
                getAllNotifications()
              }
            }))
      }
      }}>{btn?.title}</button>
     }else if(btn?.type=='cancel'){
      return <button className='notification-cancel'  onClick={()=>{
         if( obj?.actionType && obj?.endpoint){
        notificationAction(
          obj?.actionType,
           obj?.endpoint,
           btn?.payload
          ,((response,error)=>{
            if(response){
                getAllNotifications()
              }
            }))
      }
      }}>{btn?.title}</button>
     }
    })
  }

</div>
  
  
   :null

  
}


</div>
})}
{
  notificationRes?.loadMore? <div className='load-more-notifications' >
  {loading?<Spin indicator={<LoadingOutlined spin />}/>  :
   <div onClick={() =>{loadMoreHandler()}}>
                    Load More
                    </div>}
                </div>:null
}
{
  loading?
  [...new Array(5)].map((e,i)=>{
 return <><div className='sidebar__notification__card' >
<div className='notification__card__header'>
    <div className='notification__card__profile animated-bg animated-bg-text' ></div>
    <div className='notification__card__content'>
          <div className='notification__card__title animated-bg animated-bg-text' style={{width:'100%',height:'18px'}}> </div>
          <div className='notification__card__title animated-bg animated-bg-text' style={{width:'100%',height:'18px'}}> </div>
          <div className='notification__card__date animated-bg animated-bg-text' style={{width:'25%',height:'10px'}}></div>
    </div>
</div>
{i<3?<div className='notification__card__actions'>
 <button className='notification-ok animated-bg animated-bg-text' style={{width:'100px',height:'30px'}} ></button>
 
</div>:null
}
  
  {i==4?<div className='load-more-notifications'  style={{height:'10px'}}> <div className='animated-bg animated-bg-text' style={{width:'100px',height:'20px'}}></div></div>:null}
  
</div>
</> 
  })
  :  !notificationRes?.notifications?

  <div className='no-notification-data' >

 
                 <img src={no_job}/>
                 <h3>No Data Found!...</h3>
             
        
  </div>:
  null
}

</div>
    
   
    
 
  </div>


  
  
</section>
    </>
  )
};


const NotificationCard=({ele})=>{

  const [obj,setObj]=useState({});

  useEffect(()=>{
    try{
      let parsedJson=JSON.parse(ele.info);
      setObj( parsedJson)
      }catch(e){
        setObj({})
      }
  },[ele])





  return (
    <div className='notification-body'>
  <div className='sidebar__notification__card' style={{border:'none',padding:0,height:'max-content !impotant',paddingBottom:'10px'}} >
<div className='notification__card__header'>
    <div className='notification__card__content'>
          <div className='notification__card__title'>{ele?.message} </div>

    </div>

</div>


{
 
  obj?.action==true?
  
  <div className='notification__card__actions'>
  {
     obj?.buttons?.map(btn=>{
    
     if(btn?.type=='ok'){
      {console.log("hii",btn,btn?.type)}
      return <button className='notification-ok'  onClick={()=>{
    
    notificationAction(
          obj?.actionType,
           obj?.endpoint,
           btn?.payload,((response,error)=>{
              console.log("error",error,response)
              if(response){
                setObj({
                  "action":true,
          "buttons":[
           {
              "title":"Activated",
              "key":"active",
              "value":true,
              "type":'ok'
            }
          ]
                })
              }
            }))

      }}>{btn?.title}</button>
     }else if(btn?.type=='cancel'){
      return <button className='notification-cancel'  onClick={()=>{
       notificationAction(
          obj?.actionType,
           obj?.endpoint,
           btn?.payload,((response,error)=>{
              console.log("error",error,response)
              if(response){
          
            setObj({
                  "action":true,
          "buttons":[ {
              "title":"Denied",
              "key":"active",
              "value":true,
              "type":'cancel'
            }
          ]
                })
              }
          
              
            }))
      }}>{btn?.title}</button>
     }
    })
  }

</div>
  
  
   :null

  
}


</div>


    </div>
  )

}

export default NotificationsUtil;
