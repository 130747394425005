import React from "react";
import { PieChart, Pie, Cell, Tooltip, Legend,   LabelList
} from 'recharts';

const CustomPieChart = ()=>{
    const colors = ["#9254DE","#EFDBFF"];
    const data = [{name:"Qualified", value:16},{name:"Unqualified",value:32}];
    return (
  <div style={{display:'flex',flexDirection:'row'}}>
  <PieChart width={125} height={150}>
    <Pie
      data={data}
      cx="50%"
      cy="50%"
      innerRadius={0}
      outerRadius={55}
      fill="#000000"
      paddingAngle={0}
      dataKey="value"
      
    >
      {data.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={colors[(index) % colors.length]} />
      ))}
   

    </Pie>
  
  </PieChart>
    <div style={{display: 'flex', flexDirection: 'column',fontSize: '14px',justifyContent:'center'}}>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center',justifyContent:'space-between'}}>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',gap:'0.5rem'}}>
                <div style={{width: '12px', height: '12px', borderRadius: '2px', backgroundColor: "#9254DE"}}></div>
                <div style={{fontSize: '14px', marginBottom: '0px', fontFamily:'Roboto', fontSize:'14px'}}>Qualified candidates</div>
            </div>
            <div>
                {data[0].value}
            </div>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center',gap:'24px'}}>
            <div style={{display:'flex',flexDirection:'row',gap:'0.5rem',alignItems:'center'}}>
                <div style={{width: '12px', height: '12px', borderRadius: '2px', backgroundColor: "#EFDBFF"}}></div>
                <div style={{fontSize: '14px', marginBottom: '0px', fontFamily:'Roboto', fontSize:'14px'}}>Unqualified candidates</div>
            </div>
            <div>
                {data[1].value}
            </div>
        </div>
    </div>
  </div>
 
    );


}

export default CustomPieChart;