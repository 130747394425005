import { create } from "zustand";
export const useVacancyStore=create(set=>({
    activeTab:'Requests',
    sortJobTitle:null,
    sortDateTime:null,
    creatorFormModal:false,
    responderFormModal:false,
    vacancyCreatorFlag:true,
    viewFormModal:false,
    submittedData:[],
    filtersList:null,
    responseData:[],
    // pageMeta:'',
    jobs:[{},{},{}],
   
    defaultTemplate:[],
    recruitmentData:{},
    templateDropdown:[],
    // setSubmittedData: (newEntry) => set((state) => ({
    //     submittedData: [...state.submittedData, newEntry]
    // })),
   

    
    jobsResponse:[],
    jobFilters:null,
    layoutType:'grid',
    nFilters:{},
    vacancyData:null,


  
updateTemplateDropdown:(val) =>set((state) =>({templateDropdown:val})),
updateRecruitmentData:(val) =>set((state) =>({recruitmentData:val})),
updateDefaultTemplate:(val) =>set((state) =>({defaultTemplate:val})),    
setSubmittedData: (val) => set((state) => ({ submittedData: [...state.submittedData, val] })),
    setResponseData: (val)=> set(state=>({responseData:val})),
    setResponderFormModal: (val)=> set({responderFormModal:val}),
    setCreatorFormModal:(val)=>set(state=>({creatorFormModal:val})),
    setFiltersList: (val)=> set({filtersList:val}),
    setViewFormModal : (val)=>set(state=>({viewFormModal:val})),
    setVacancyCreatorFlag : (val)=>set(state=>({vacancyCreatorFlag:val})),
    setSortJobTitle: (val)=>set({sortJobTitle:val}),
    setSortDateTime: (val)=>set({sortDateTime: val}),
    setActiveTab:(val)=>set(state=>({activeTab:val})),
    setPageMeta: (val)=>set({pageMeta:val}),
    setJobsResponse:(val)=>set({jobsResponse:val}),
    setJobFilters: (val)=> set({jobFilters:val}),
    setnFilters:(val)=>set({nFilters:val}),
    setVacancyData:(val)=>set({vacancyData:val}),



  resetFilters : ()=>set({
    sortJobTitle:null,
    sortDateTime:null,
    creatorFormModal:false,
    responderFormModal:false,
    viewFormModal:false,
    submittedData:[],
    filtersList:null,
    responseData:[],

  }),
  setLayoutType:(val)=>set(state=>({layoutType:val}))
   
}))

export const useAnalyticsStore=create(set=>({

  analyticsData:null,
  rangeType:"Week",
  setAnalyticsData:(val)=>set({analyticsData:val}),
  setRangeType: (val)=>set({rangeType:val}),

}))

export const useActivitiesStore=create(set=>({
  activitiesData:{},
  setActivitiesData:(val)=>set({activitiesData:val}),
}))