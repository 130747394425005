import Api from "../../../service/Api"
import { useGet } from "../../../custom-hooks/useApi"

export const postVacancyRequests = (params, callback) => {
    Api.post('/api/hiring/postVacancy').params(params)
    .send((response, error) => {
       callback(response,error)
      })
}

export const getVacancyRequests = (params,callback) => {
  
    Api.get('/api/hiring/vacancyRequests').params(params)
    .send((response, error) => {
        callback(response,error)
      })
}

export const updateVacancyRequests = (params,callback) => {
    Api.patch('/api/hiring/updateVacancyRequest').params(params)
    .send((response, error) => {
        callback(response,error)
      })
}
//api/hiring/updateVacancyState
export const updateVacancyState = (params,callback) => {
    Api.patch('/api/hiring/updateVacancyState').params(params)
    .send((response, error) => {
        callback(response,error)
      })
}


export const deleteVacancyRequest = (jobId,callback) => {
    Api.delete(`/api/hiring/deleteVacancyRequest?jobId=${jobId}`).send((response, error) => {
        callback(response,error)
      })
}


export const getJobs = (params,callback) => {
   
    Api.get('/api/hiring/job').params(params)
    .send((response, error) => {
        callback(response,error)}
    )}


export const updateJobTemplate = (params,callback) =>{
    Api.patch('/api/hiring/updateJobTemplate').params(params)
    .send((response, error) => {
       callback(response,error)
      })
}


export const getAnalytics = (params,callback)=>{
    Api.post('/api/hiring/analytics').params(params)
    .send((response,error)=>{
        callback(response,error)
    })

}


export const getVacancyActivities = (params,callback)=>{
    Api.get('/api/hiring/getVacancyActivties').params(params)
    .send((response,error)=>{
        callback(response,error)
    })

}

export const getJobOverview=(params,callback)=>{
    Api.get('/api/hiring/job').params(params)
    .send((response, error) => {
        callback(response,error)
      })
}


export const generateJd=(params,callback)=>{
    Api.post('/api/hiring/ai-task').params(params)
    .send((response,error)=>{
        callback(response,error)
    })
}