import React, { useEffect, useState } from "react";
import "./analytics.css"
import CustomTitle from "./customTitle";
import VacancyAnalysis from "./vacancyAnalysis";
import VacancySummary from "./vacancySummary";
import { getAnalytics } from "../Action/action";
import { useAnalyticsStore } from "../store/store";
import { DatePicker, Select } from 'antd';
import dayjs from 'dayjs';


const { RangePicker } = DatePicker;


const Analytics = ()=>{

  const [startDate, setStartDate] = useState(dayjs("30/10/2024","DD/MM/YYYY"));
  const [endDate, setEndDate] = useState(dayjs("06/11/2024","DD/MM/YYYY"));
  const [year, setYear] = useState('2024');
  const [month, setMonth] = useState('10');

  const {analyticsData, setAnalyticsData, rangeType, setRangeType} = useAnalyticsStore(state=>state);

     const vacancyAnalysisData = [
    { name: 'Full-time', value: 32, index: 0 },
    { name: 'Part-time', value: 43 , index: 1  },
    { name: 'Contract', value: 14, index: 2 },
    { name: 'Freelance', value: 28, index: 3 },
    { name: 'Internship', value: 6, index: 4  },
    { name: 'Undetermined', value: 6, index: 5  }
  ];
//   const departmentData = Object.entries(data?.departmentCounts??{})?.map(([key, value],index) => ({
//     name: key,
//     value: value,
//     index: index
//   }));
  const totalVCount = vacancyAnalysisData?.reduce((sum, item) => sum + item.value, 0);
  const colors = ['#BAE7FF',  '#91D5FF', '#69C0FF','#40A9FF', '#1890FF',"#D0D0D0"];

  const activeColors = ["#13C2C2","#5CDBD3", "#B5F5EC"];
  const inactiveColors = ["#95DE64", "#FF7875", "#FF9C6E"];


    const successCardData = [
   { name: 'Recruited', value: 24, index: 0 },
   { name: 'Active', value: 104 , index: 1  },
   { name: 'On-hold', value: 256, index: 2 },
  
 ];
  const successCardColors=  ["#95DE64","#B37FEB", "#FFC069"];
  const totalCount = successCardData?.reduce((sum, item) => sum + item.value, 0);

  const summaryColors=["#B37FEB","#EFDBFF"];
  // const data = [
  //   { name: '18-25', Male: 75, Female: 80, maleCount:75, femaleCount:80 },
  //   { name: '26-35', Male: 70, Female: 75, maleCount:70, femaleCount:75 },
  //   { name: '36-45', Male: 70, Female: 80, maleCount:70, femaleCount:80 },
  //   { name: '46-55', Male: 65, Female: 70, maleCount:65, femaleCount:70 },
  //   { name: '56-65', Male: 70, Female: 75, maleCount:70, femaleCount:75 }
  // ];
  const data = analyticsData?.summary?.vacancyData?.map(item => {
    const date = item.date;
    // Reverse the last two characters in the date string.split("").reverse().join("")
    const reversedDay = date.slice(-5).split("-").reverse().join("-");
    return {
        name: reversedDay,
        activeCount: item.activeCount,
        inactiveCount: item.inactiveCount,
        maleCount: item.activeCount,
        femaleCount: item.inactiveCount
    };
});
  const dataActive = [
    { name: 'Pre-Interview Stage', value: analyticsData?.stageType?.preInterviewStageCount},
    { name: 'Interview Stage', value: analyticsData?.stageType?.interviewStageCount },
    { name: 'Post-Interview Stage', value: analyticsData?.stageType?.postInterviewStageCount },
  
  ];
  
  
  const dataInactive = [
    { name: 'Recruited', value: 32 },
    { name: 'Force Closed', value: 14 },
    { name: 'On-hold', value: 4},
    
  ];
  const onChange = (value) => {
    setRangeType(value)
    console.log(`selected ${value}`);
  };

  const onYearChange = (value)=>{
    setYear(value);

  }
  const onMonthChange = (value)=>{
    setMonth(value)
  }
  const onSearch = (value) => {
    
    console.log('search:', value);
  };


  const handleRangeChange = (dates) => {
    console.log("DATES",dates);
    if (dates) {
      setStartDate(dates[0]);
      setEndDate(dates[1]);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  useEffect(()=>{
    console.log(startDate,endDate,"DATES")

  },[startDate,endDate])


  useEffect(()=>{
    
    let params={
      rangeType: rangeType,
      startDate: rangeType==="Week"?dayjs(startDate).format("YYYY-MM-DD"):null,
      endDate: rangeType==="Week"?dayjs(endDate).format("YYYY-MM-DD"):null,
      year: rangeType!=="Week"?year:null,
      month: rangeType==="Month"?month:null,

    };
    getAnalytics(params,(response,error)=>{
      if (error) {
        console.error("Error :", error);
      } else {
      
        setAnalyticsData(response)
        
        
        console.log("Analytics check :",response?.summary);
      }
    })
  },[rangeType,startDate,endDate,year, month])

 

    return (
        <div className="analytics-container">
          <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
            <div className="analytics-header">
                <div>
                    Analytics & Reports
                </div>
                <div>
                     Displaying 7 days data against the previous 7 days data.
                </div>
            </div>
     
            <div style={{display:'flex', gap:'16px', alignItems:'center'}}>
            <Select
                showSearch
                placeholder="Select"
                optionFilterProp="label"
                onChange={onChange}
                onSearch={onSearch}
                value={rangeType}
                options={[
                  {
                    value: 'Week',
                    label: 'Week',
                  },
                  {
                    value: 'Month',
                    label: 'Monthly',
                  },
                  {
                    value: 'Year',
                    label: 'Yearly',
                  },
                ]}
                className="Select-an"
            />
              {rangeType==="Week"?<RangePicker
                defaultValue={[startDate, endDate]}
                format="DD-MM-YYYY"
                style={{ borderRadius: '4px' }}
                onChange={handleRangeChange}
              />:
              rangeType==="Year"?
              <Select
                showSearch
                placeholder="Select"
                optionFilterProp="label"
                onChange={onYearChange}
               value={year}
               
                options={[
                  {
                    value: '2024',
                    label: '2024',
                  },
                  {
                    value: '2023',
                    label: '2023',
                  },
                  {
                    value: '2022',
                    label: '2022',
                  },
                ]}
                 className="Select-an"
              />
              :
              <>
              <Select
                showSearch
                placeholder="Select"
                optionFilterProp="label"
                onChange={onMonthChange}
               value={month}
              
                options={[
                  {
                    value: '11',
                    label: 'November',
                  },
                  
                  {
                    value: '10',
                    label: 'October',
                  },
                  {
                    value: '09',
                    label: 'September',
                  },
                 
                ]}
                 className="Select-an"
              />
              <Select
                showSearch
                placeholder="Select"
                optionFilterProp="label"
                onChange={onYearChange}
               value={year}
               
                options={[
                  {
                    value: '2024',
                    label: '2024',
                  },
                  {
                    value: '2023',
                    label: '2023',
                  },
                  {
                    value: '2022',
                    label: '2022',
                  },
                ]}
                
              />
              
              </>
              }
            </div>
          </div>
            <div >
            <CustomTitle 
                title="Vacancy Overview" styles={{width:'4px', height:'24px', backgroundColor:'#2337C6'}} />
            <div className="analytics-subcontainer1" >
                    <VacancySummary 
                        type="vacancysummary" 
                        title="Vacancy Summary" 
                        content="The ratio of active to inactive job vacancies."
                        colors={summaryColors}
                        data={data}
                    />
                    <VacancySummary 
                        type="active"
                        title="Active Vacancies" 
                        content="Monitor all active vacancies."
                        colors={activeColors}
                        data={dataActive}
                    />
            </div>
            <div className="analytics-subcontainer2">
                    <VacancySummary 
                        type="inactive"
                        title="In-active Vacancies" 
                        content="View details on in-active job vacancies."
                        colors={inactiveColors}
                        data={dataInactive}
                    />
                    <VacancyAnalysis
                        data={vacancyAnalysisData} 
                        colors={colors} 
                        totalCount={totalVCount} 
                        title="Vacancy Analysis by Core Criteria" 
                        content="Analyze vacancies by key factors to enhance your hiring strategy."
                       
                    />
            </div>
            </div>
          

        </div>
    );

}

export default Analytics;