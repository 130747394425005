import React from "react";
import CustomBarChart from "./customBarChart";
import "./newCard.css"

const NewCard = ()=>{
    const data = [
        { date: '12/4', value: 4 },
        { date: '13/4', value: 6 },
        { date: '14/4', value: 10 },
        { date: '15/4', value: 12 },
        { date: '16/4', value: 10 },
        { date: '17/4', value: 8 },
      ];

    return (
        <div style={{display:'flex',flexDirection:'column',gap:'1.5rem'}}>
            <div style={{display:'flex',flexDirection:'row',gap:'8px'}}>
                <div style={{width:'4px',height:'24px', backgroundColor:"#006D75"}}></div>
                <div  style={{fontSize:'16px', fontWeight:'600'}}>Candidate Sourcing</div>
            </div>
        
            <div className="newcard-container">
                <div>8</div>
                <div>Total Candidates in Queue</div>
                <div>2 new candidates joined the pipeline today</div>
            </div>
                <hr style={{border:'1px solid #E9E9E9',width:'100%'}}></hr>
                <div className="newcard-container1">
                    <div>48</div>
                    <div>Total candidates considered</div>
                </div>
                <div >
                    <div style={{textAlign:'center'}}>Week2</div>
                    <CustomBarChart data={data}/>
                 </div>
        </div>
    );

}

export default NewCard;