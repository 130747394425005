import React, { useState } from 'react';
import searchIcon from "../../assets/searchIcon.svg"
import clearIcon from "../../assets/clearIcon.svg"

const SearchCompo = ({ onSearch, placeholder = 'Search' }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = () => {
    if (onSearch) {
      onSearch(searchTerm);
    }
  };
  const clearSearch = () => {
    setSearchTerm('');
    if (onSearch) {
      onSearch('');
    }
  };

  return (
    <div style={styles.searchContainer}>
      <input
        type="text"
        value={searchTerm}
        onChange={(e) =>{ 
           setSearchTerm(e.target.value)
          onSearch(e.target.value)
        }}
        placeholder={placeholder}
        style={{...styles.input,
          // borderRight: searchTerm? 'none':'',
          borderRight: 'none'
        }}
      />
       {searchTerm && (
        <button onClick={clearSearch} style={styles.clearButton}>
          <img src={clearIcon} alt="clear" />
        </button>
      )}
      <button onClick={handleSearch} style={styles.button}>
        <img src={searchIcon} alt="searchIcon"/>
      </button>
    </div>
  );
};

const styles = {
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    // border: '1px solid #ccc',
    borderRadius: '5px',
    overflow: 'hidden',
    maxWidth: '276px',
    minHeight: '40px',
    border: '1px solid #D0D0D0',


  },
  input: {
    border: 'none',
    borderRadius: '4px 0px 0px 4px',
    padding: '10px',
    flex: 1,
    color: 'var(--Texts-disabled, #000)',

    height:'100%',
    fontFamily: 'Figtree',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: 'normal',
    outline: 'none',
  },
  clearButton: {
    backgroundColor:'white',
    border: 'none',
    position: 'relative',
    right: '0px', // Positioning clear button inside the input
    cursor: 'pointer',
    padding: '0 8px',
    height: '100%',
    
  },
  button: {
    backgroundColor: '#1890FF',
    color: 'white',
    border: 'none',
    padding: '10px',
    cursor: 'pointer',
    borderRadius: '0px 4px 4px 0px',
    width: '37px',

    height:'100%',
  },

};

export default SearchCompo;

