import React, { useState } from "react";
import { Select } from "antd";
//import "./createRequests.css";
import "./multiSearchSelect.css";

const { Option } = Select;

const MultiSearchSelect = () => {
  const [selectedValues, setSelectedValues] = useState([]);

  const handleChange = (value) => {
    setSelectedValues(value);
    console.log("Selected values: ", value);
  };

  const options = [
    { value: "Electrical", label: "Electrical" },
    { value: "Computer Science", label: "Computer Science" },
    { value: "Electronics", label: "Electronics" },
    { value: "Automobile", label: "Automobile" },
    { value: "Finances", label: "Finances" },
  ];

  return (
    <div className="input-group">
      <label htmlFor="searchable-select">
        Specializations
      </label>
    <Select
      mode="multiple"
      allowClear
      showSearch
      placeholder="e.g. Electrical, Automobile, etc."
      value={selectedValues}
      onChange={handleChange}
      style={{ width: "100%" }}
      dropdownStyle={{ maxHeight: 150, overflow: "auto" }}
      maxTagCount="responsive" // Limits visible tags
      className="custom-multi-select"
      filterOption={(input, option) =>
        option.label.toLowerCase().includes(input.toLowerCase())
      }
      options={options}
    />
    </div>
  );
};

export default MultiSearchSelect;
