import React,{useEffect, useState} from "react";
import CustomButton from "./customButton";
import Generate from "../../assets/generateIcon.svg"
import JobIndustrySelector from "./jobIndustrySelector";
import "./jobDescription.css"
import JobLevel from "./jobLevel";
import design from "../../assets/backgrounddesign.svg"
// import background from "../../assets/background.svg"
import Header from "../../landing/header";
import tipIcon from "../../assets/tip.svg"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styling
import { useNavigate } from "react-router-dom";
import { useStore } from './store/store.js';
import EditorToolbar, { modules, formats } from "../../components/EditorToolbar/EditorToolbar";

const JobDescription = (()=>{
    const {jdData,setJdData,titleJob} = useStore(state=>state);
    const navigate = useNavigate();
    useEffect(()=>{
      if(!jdData){
        navigate('/createJd')
      }
    },[])

    const handleChange = (value) => {
       // setJdData(value); // Update content when user edits
    };
    return (
       <>
        <Header color={'#2337C6'} />
        <div className="job-description">
         <div class="flex-overlay-container">
         <img src={design} class="image2" alt="Second Image"/>
        </div>

          <div  className='child-container'>
         
          <h1 className="animated-bg-text">{titleJob}</h1>
  
         {jdData?
         <>
         <EditorToolbar/>
         
         <ReactQuill
            theme="snow"
            value={''}
            onChange={handleChange}
            placeholder={"Write custom job description"}
            modules={modules}
            formats={formats}
            className="quill-editor"
              
          />
         </>
          :<>
          <h3 class="card-title animated-bg animated-bg-text" id="title">
                &nbsp;
            </h3>
               <h3 class="card-title animated-bg animated-bg-text" id="title" style={{height:'20px'}}>
                &nbsp;
            </h3>
            <p class="card-excerpt" id="excerpt">
                &nbsp;
                <span class="animated-bg animated-bg-text">&nbsp;</span>
                <span class="animated-bg animated-bg-text">&nbsp;</span>
                <span class="animated-bg animated-bg-text">&nbsp;</span>
            </p>
            <h3 class="card-title animated-bg animated-bg-text" id="title" style={{height:'20px'}}>
                &nbsp;
            </h3>
            <p class="card-excerpt" id="excerpt">
                &nbsp;
                <span class="animated-bg animated-bg-text">&nbsp;</span>
                <span class="animated-bg animated-bg-text">&nbsp;</span>
                <span class="animated-bg animated-bg-text">&nbsp;</span>
            </p>
          </>
            
            }
</div>
    </div>
</>
        
    );

})

export default JobDescription;