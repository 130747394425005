import React, { useEffect } from "react";
import "./vacancies.css"
import CustomTabs from "./CustomTabs";
import CreateRequests from "./CreateRequests";
import Jobs from "./jobs/Jobs";
import addButtonIcon from "../../assets/addButtonIcon.svg"
import { useVacancyStore } from "./store/store"
import { useStore } from "../../sidebar/store/store.js";
import Analytics from "./analytics/analytics.js";
import exportIcon from "../../assets/exportIcon.svg"
import {useGet} from "../../custom-hooks/useApi.js"

function Vacancies ()
{
    const {visible,setVisible} = useStore(state=>state);
    const {creatorFormModal, setCreatorFormModal,submittedData,
      responseData,vacancyCreatorFlag,responderFormModal,setResponderFormModal,
      setActiveTab,activeTab,formData,setFormData

    } = useVacancyStore(state=>state);
    const { data, refetch } = useGet("/api/hiring/jobCode");

    const tabs = [
        { 
          name: "Requests", 
          content: <CreateRequests/>,
          style:{
            width:'100px',
            marginLeft:'0px',
    
          }
        },
        { 
          name: "Active", 
          content: <Jobs status={"active"}/>,
          style:{
            width:'100px',
            marginLeft:'200px',
          }
        },
        { 
          name: "Inactive", 
          content:    <Jobs status={"inactive"}/>,
          style:{
            width:'100px',
            marginLeft:'300px',
          }
        },
        {
          name: "Analytics & Reports",
          content: <Analytics/>,
          style:{
            width:'160px',
            marginLeft:'460px',
          }
        },
        // {
        //   name: "Settings",
        //   content: <div>
        //     <h3>Automatically generate job vacancy code</h3>
        //     <p>Our system generates a unique code for every new job vacancy, ensuring easy reference throughout the hiring cycle and reducing the chance of duplicates.</p>
        //     </div>,
        //   style:{
        //         width:'160px',
        //         marginLeft:'620px',
        //       }
       
        // }
      ];
    const filteredTabs = vacancyCreatorFlag ? tabs.slice(0, 3) : tabs;
    const handleClick = (type)=>{
      if(type==="Creator"){
      setCreatorFormModal(!creatorFormModal);
      }
      if(type==="Responder"){
        setResponderFormModal(!responderFormModal)
        try {
          // Trigger the API call manually
          const response =  refetch(); 
          setFormData({
            ...formData,
            jobCode: response.jobCode, // Assuming `response.data` holds the jobCode
          });
        } catch (error) {
          console.error("Failed to fetch job code:", error);
        }
      }
    }


  


    return (
       
        <div className="vacancies-wrapper">
     
        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',padding:'20px'}}>
        <div >
            <h3 style={{margin:'3px 0px'}}>Vacancies</h3>
            <p style={{margin:0}}>Manage job vacancies and optimise your recruitment process for maximum efficiency</p>
        </div>
            {(responseData?.vacancies?.length>0&&vacancyCreatorFlag )?
                <div>
                  <button id="raiseReqForm" style={{cursor:'pointer'}} type="button" onClick={()=>{
                    handleClick("Creator")}}  
                      className="dashed-box-button"> 
                    <img src={addButtonIcon} />
                    <div>Raise a request</div>
                  </button>
                </div>:
                vacancyCreatorFlag===false &&  activeTab!=="Analytics & Reports"?
                <div>
                  <button id="raiseReqForm" style={{cursor:'pointer'}} type="button" onClick={()=>{
                    handleClick("Responder")
                  }}  
                      className="dashed-box-button"> 
                    <img src={addButtonIcon} />
                    <div>Create vacancy</div>
                  </button>
                </div>:
                activeTab==="Analytics & Reports"?
                <div>
                <button id="exportReport"> 
                  <img src={exportIcon}/>
                 <div> Export report</div>
                </button>
              </div>
                :null}
            </div>
            {<CustomTabs tabs={tabs}/>}
         

    
        
    </div>
       
    );
}

export default Vacancies;