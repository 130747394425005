
import React from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';

const { Content } = Layout;



function VacancyWrapper() {

   return  <Layout   >
    <Content>
      <Outlet/>
    </Content>
  </Layout>
}





export default VacancyWrapper;

