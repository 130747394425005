import React from "react";
import CustomPieChart from "./customPie";
import "./resumeCandidate.css"

const ResumeCandidate = ()=>{

    return (
        <div style={{display:'flex',flexDirection:'column',gap:'1.5rem'}}>
        <div style={{display:'flex',flexDirection:'row', gap:'8px'}}>
            <div style={{width:'4px',height:'24px', backgroundColor:"#13C2C2"}}></div>
            <div style={{fontSize:'16px', fontWeight:'600'}}>Resume Candidate</div>
        </div>
     
        <div className="resumecandidate-container">
            <div>4</div>
            <div>Total Candidates in Queue</div>
            <div>Last updated on Apr 10, 2024 13:52:41</div>
        </div>
        <hr style={{border:'1px solid #E9E9E9', width:'100%'}}></hr>
        <div className="resumecandidate-container1">
            <div>33%</div>
            <div>Qualification Rate</div>
        </div>
        <CustomPieChart/>
        </div>
    );

}

export default ResumeCandidate;