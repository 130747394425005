import React, { useRef,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AutoComplete, Input } from "antd";
import "./createRequests.css"
import RequestsIcon from "../../assets/requestsIcon.svg"
import { useState } from "react";
import CustomIncrement from "./CustomIncrement";
import addButtonIcon from "../../assets/addButtonIcon.svg"
// import { DatePicker } from "antd";
import dayjs from 'dayjs';
// import 'react-datepicker/dist/react-datepicker.css';
import NumberInputExample from "./NumberInput";
import SearchableSelect from "./searchableSelect";
import SearchCompo from "./searchComponent";
import MyDatePicker from "./myDatePicker.js";
import OverviewCard from "./overviewCard";
import editTable from "../../assets/editTable.svg"
import StatusCard from "./statusCard";
import { useVacancyStore } from "./store/store";
import Pagination from "./customPagination";
import filterIcon from "../../assets/filterIcon.svg"
import selectedFilterIcon from "../../assets/filterIconSelected.svg"
import FilterDropdown from "./customFilterDropdown";
import EditIcon from "../../assets/EditIcon.svg"
import deleteIcon from "../../assets/deleteIcon.svg"
import viewIcon from "../../assets/viewIcon.svg"
import {postVacancyRequests, getVacancyRequests, updateVacancyRequests,deleteVacancyRequest,updateVacancyState} from "../vacancies/Action/action.js"
import descArrow from "../../assets/descSort.svg"
import aescArrow from "../../assets/aescSort.svg"
import aescSelected from "../../assets/aescSelectedSort.svg"
import descSelected from "../../assets/descSelected.svg"
import noDataIcon from "../../assets/noDataIcon.svg"
import Table from "../../components/Table/Table.js";
import { isMultiple } from "rc-select/lib/BaseSelect/index.js";
import Select from "rc-select";
import CustomTimeline from "./vacancy_details/activities/timelineItem.js";
import closeIcon from "../../assets/closeIcon.svg"
import MultiSearchSelect from "./multiSearchSelect.js";
import "./jobtitleSuggestions.css"
import QualificationInput from "./qualificationInput.js";
import moment from "moment";



const CreateRequests =  ()=>{

    const [startDate,setStartDate] = useState('');
    const [formData,setFormData] = useState({
      jobTitle: '',
      dateTime:'',
      department: '',
      approver: '',
      vacancyStatus: '',
    });
 
    const navigate = useNavigate();
    const [submitAction, setSubmitAction] = useState('');
    const [currentPage,setCurrentPage] = useState(1);
    const [search, setSearch] = useState('');
    const [limit,setLimit] = useState(8);
    const [dropdownOptionsName, setDropdownOptionsName] = useState(null);
    const [currentStatus,  setCurrentStatus] = useState('');
    const [dropdown, setDropdown] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [editFormData,setEditFormData] = useState(false);
    const [editFormModal, setEditFormModal] = useState(''); //has the data related to that job
    
    const [editModalPosition, setEditModalPosition] = useState({ top: 0, left: 0 });
    const [filterModalPosition, setFilterModalPosition] = useState({top: 0 ,let: 0});
    const totalPages = 5;
    const[loading,setLoading]=useState(true);
    const dateFormatList = ['DD/MM/YYYY'];
    const [jobTitle,setJobTitle] = useState('');


    const {creatorFormModal,setCreatorFormModal,
            submittedData,setSubmittedData,
            filtersList,setFiltersList,
            viewFormModal,setViewFormModal,
            vacancyCreatorFlag,
            responseData,setResponseData,
            responderFormModal,setResponderFormModal,
            sortJobTitle,setSortJobTitle,
            sortDateTime,setSortDateTime} = useVacancyStore(state=>state);


    const toggleForm = () =>{
    
        setFormData({ jobTitle: '', dateTime:'',department: '', approver: '',vacancyStatus:'' }); // Reset form
        setEditFormData(false);
        setCreatorFormModal(false);
        setViewFormModal(false);
    }

    const onGoBack = ()=>{
      setViewFormModal(false)
    }

    const toggleReqForm = ()=>{
      setCreatorFormModal(!creatorFormModal);
    }

    const toggleFormResponder = ()=>{
      setFormData({ jobTitle: '', jobCode:'',department: '', approver: '',vacancyStatus:'' })
      setResponderFormModal(false);
    }

    const handleOutsideClick = (e) => {
        if (e.target.className === "modal-overlay") {
          // setIsVisible(false);

        }
      };

    const handleViewVacancyClick = (id)=>{
      navigate(`/vacancies/${id}`)
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const [options, setOptions] = useState([]);

    const handleSearch = (value) => {
      if (value) {
        const filteredOptions = jobTitles
          .filter((title) => title.toLowerCase().includes(value.toLowerCase()))
          .map((title) => ({ value: title }));
        setOptions(filteredOptions);
        setFormData({
          ...formData,
          jobTitle: value,
        });
      } else {
        setOptions([]);
      }
    };
  
    const handleSelect = (value) => {
      setFormData((prev) => ({ ...prev, jobTitle: value }));
    };


    const handleSubmit = (e) =>{
      
      e.preventDefault();

      const now = new Date();

      // Convert to IST by adding the IST offset (5 hours 30 minutes) from UTC
      const offsetIST = 5.5 * 60 * 60 * 1000;
      const istTime = new Date(now.getTime() + offsetIST);
      
      // Extract day, month, year, hours, minutes, and seconds
      const day = String(istTime.getUTCDate()).padStart(2, '0');
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const month = monthNames[istTime.getUTCMonth()]; // Get the month name
      const year = istTime.getUTCFullYear();
      const hours = String(istTime.getUTCHours() + 5).padStart(2, '0'); // Convert to IST (UTC+5)
      const minutes = String(istTime.getUTCMinutes()).padStart(2, '0');
      
      // Format the date as "OCT DD YYYY IST TIME"
      const formattedDate = `${month} ${day} ${year} ${hours}:${minutes} IST`;
      
      console.log("Current IST Date and Time:", formattedDate);


      if(vacancyCreatorFlag){
        setCreatorFormModal(!creatorFormModal);
      }
      else{
        setResponderFormModal(!responderFormModal);
      }


      let newEntry={};

      if(submitAction==='raiseRequest'){
      
      newEntry = {
        ...formData,
        dateTime:formattedDate, 
        vacancyStatus: "Pending",
        draft : false,
        approverRole: "Creator"
        //id: Date.now(), Generate a unique key using timestamp
        
      };
      setFormData(newEntry);
      
     
      console.log(formData,"FORMDATACHECK");
      }
     
      else if(submitAction==='draft'){
        newEntry = {
          ...formData,
          dateTime: formattedDate, 
          vacancyStatus: "Draft",
          draft: true,
          approverRole: "Creator"
         // id: Date.now(),  Generate a unique key using timestamp
          
        };
        setFormData(newEntry)
        
        console.log(formData,submittedData,Date.now(),"FORMDATACHECK");
      }

      else if(submitAction==='createRequest'){
        newEntry = {
          ...formData,
          dateTime: formattedDate, 
          approverRole: "Responder",
      
         // id: Date.now(),  Generate a unique key using timestamp
          
        };
        setFormData(newEntry)
        
        console.log(formData,submittedData,Date.now(),"FORMDATACHECK");
      }


      console.log(newEntry,"POSTCHECK")


    if(editFormData===false){
      
        postVacancyRequests(newEntry, (response, error) => {
          if (error) {
            console.error("Error posting vacancy:", error);
          } else {
            console.log("Vacancy posted successfully:", response);
            
            
            setSearch('');
            
            getRequests()
          
          }
        });
    }
    if(editFormData===true){
            updateVacancyRequests(newEntry,(response,error)=>{
              if (error) {
                console.error("Error posting vacancy:", error);
              } else {
                console.log("Vacancy updated successfully:", response);
                
                setSearch('');
                getRequests()
              
              }
            });
            setEditFormData(false);
    }
   
      setSubmittedData(newEntry)
     //setSubmittedData((prev) => [...prev, newEntry]); // Store submitted data
      setFormData({ jobTitle: '', dateTime:'',department: '', approver: '',vacancyStatus:'' }); // Reset form
      console.log(formData,submittedData,"FORMDATACHECK")
      console.log(Array.isArray(submittedData),"CHECKCHECK");
      
    }


    const getRequests = ()=>{
      const params = {
        page: responseData?.meta?.currentPage,
        limit: responseData?.meta?.itemsPerPage,
        sortTitle: sortJobTitle,
        sortDate: sortDateTime,
        status: filtersList?filtersList?.status:null,
        approverId: filtersList?filtersList?.approverId:null,
        department: filtersList?filtersList?.department:null,
        search: search,
        role: vacancyCreatorFlag===true?"Creator":"Responder",
        jobTitle: jobTitle?jobTitle:null,


      }
      setLoading(true);
      getVacancyRequests(params,(response,error)=>{
         setLoading(false)
        if (error) {
          console.error("Error posting vacancy:", error);
        } else {
        
          setResponseData(response)
          
          // setPageMeta(response?.meta)
          
          console.log("Vacancy check :",response?.vacancies);
        }
      })
    }

    useEffect(() => {
      console.log("Updated formData:", formData);
    }, [formData]);

    useEffect(()=>{
      console.log(responseData?.meta?.currentPage,"PAGINATIONCHECK")
      getRequests()
    },[filtersList,responseData?.meta?.currentPage,responseData?.meta?.itemsPerPage,search,sortDateTime,sortJobTitle,vacancyCreatorFlag,jobTitle])

    const priorityOptions =[
      {value:"low",label:'Low'},
      {value:"medium",label:'Medium'},
      {value:"high",label:'High'},
    ];
    const responderOptions = responseData?.dropDown?.responders.map(responder => ({
      value: responder?.id,
      label: responder?.userName
    })) || [];
  

    const reasonOptions =[
      {value:'Business Expansion',label:'Bussiness Expansion'},
      {value:'Replacement',label:'Replacement'},
      {value:'New Project',label:'New Project'},
      {value:'Skills gap',label:'Skills gap'},
      {value:'Others',label:'Others'},
    ];
    const currencies = [
      { label: "₹", value: "INR" },
      { label: "$", value: "USD" },
    ];
    const departments = [
      { label: "Human Resources", value: "Human Resources" },
      { label: "Finance and Accounting", value: "Finance and Accounting" },
      { label: "Marketing", value: "Marketing" },
      { label: "Sales", value: "Sales" },
      { label: "Operations", value: "Operations" },
      { label: "Information Technology", value: "Information Technology" },
      { label: "Research and Development", value: "Research and Development" },
      { label: "Customer Service and Support", value: "Customer Service and Support" },
      { label: "Legal", value: "Legal" },
      { label: "Administration", value: "Administration" },
      { label: "Strategy and Planning", value: "Strategy and Planning" },
      { label: "Procurement and Purchasing", value: "Procurement and Purchasing" },
      { label: "Health, Safety, and Environment", value: "Health, Safety, and Environment" },
      { label: "Quality Assurance", value: "Quality Assurance" },
      { label: "Public Relations and Corporate Communications", value: "Public Relations and Corporate Communications" },
    ];
    
    
    const compensationStructureOptions = [
      {value:'Per Annum',label:'Per Annum'},
      {value:'Per hour',label:'Per hour'},
      {value:'Per project',label:'Per project'},
      {value:'Per diem',label:'Per diem'},
      {value:'Per unit',label:'Per unit'},
      {value:'Per milestone',label:'Per milestone'},
      {value:'Per task',label:'Per task'},
     
    ];

    const recruitmentTypeOptions = [
      {value: 'Campus Recruitment', label: 'Campus Recruitment'},
      {value: 'Lateral Recruitment', label: 'Lateral Recruitment'},
    ];

    const handlePageChange = (page) => {
      setResponseData({
        ...responseData,   // Spread the previous state to keep other properties
        meta: {
          ...responseData.meta,  // Spread the existing meta to keep other meta properties
          currentPage: page      // Update only the currentPage
        }
      });
      //setPageMeta({currentPage:page})
    
    };
    const handleItemsPerPageChange = (size)=>{
      setResponseData({
        ...responseData,   // Spread the previous state to keep other properties
        meta: {
          ...responseData.meta,  // Spread the existing meta to keep other meta properties
          currentPage: 1 ,     // Update only the currentPage
          itemsPerPage: size,
          totalPage:0,
          totalItems:0,
          itemCount:0 
        }
      });
    };

    const [suggestions, setSuggestions] = useState([]);
    const jobTitles = [
      "Software Engineer",
      "Junior Developer",
      "Senior Developer",
      "Algorithms Engineer",
      "Machine Learning Engineer",
      "AI Engineer",
      "Java Developer",
      "React Developer",
      "Apex Developer",
      "Python Developer",
      "Senior Data Scientist",
      "Junior React Developer",
      "Data Scientist",
      "Product Manager",
      "UX Designer",
      "Project Manager",
      "Business Analyst",
      "Quality Assurance Engineer",
      "DevOps Engineer",
      "Frontend Developer",
      "Backend Developer",
      "Full Stack Developer",
      "Marketing Manager",
      "HR Specialist",
      "Finance Analyst",
      "Sales Executive",
      "Operations Manager",
      "Content Strategist",
      "Graphic Designer",
      "Customer Support Specialist",
      "IT Support Technician",
      "Cybersecurity Analyst",
      "Cloud Architect",
      "Database Administrator",
      "Machine Learning Engineer",
      "Network Administrator",
      "System Administrator",
      "SEO Specialist",
      "Social Media Manager",
      "Technical Writer",
      "Copywriter",
      "Video Editor",
      "Motion Graphics Designer",
      "Account Manager",
      "Art Director",
      "Animator",
      "Interior Designer",
      "Event Planner",
      "Logistics Coordinator",
      "Procurement Specialist",
      "Risk Manager",
      "Compliance Officer",
      "Investment Analyst",
      "Credit Analyst",
      "Tax Consultant",
      "Legal Advisor",
      "Paralegal",
      "Journalist",
      "Public Relations Specialist",
      "Policy Analyst",
      "Research Scientist",
      "Lab Technician",
      "Clinical Research Associate",
      "Pharmacist",
      "Medical Assistant",
      "Nurse Practitioner",
      "Physician Assistant",
      "Radiologic Technologist",
      "Physical Therapist",
      "Occupational Therapist",
      "Dietitian",
      "Psychologist",
      "School Counselor",
      "Special Education Teacher",
      "Kindergarten Teacher",
      "High School Teacher",
      "College Professor",
      "Librarian",
      "Training and Development Specialist",
      "Real Estate Agent",
      "Construction Manager",
      "Civil Engineer",
      "Mechanical Engineer",
      "Electrical Engineer",
      "Chemical Engineer",
      "Biomedical Engineer",
      "Environmental Scientist",
      "Geologist",
      "Urban Planner",
      "Architect",
      "Surveyor",
      "Aircraft Pilot",
      "Air Traffic Controller",
      "Logistics Manager",
      "Shipping Coordinator",
      "Warehouse Supervisor",
      "E-commerce Manager",
      "Retail Store Manager",
      "Chef",
      "Pastry Chef",
      "Restaurant Manager",
      "Bartender",
      "Hotel Manager",
      "Travel Consultant",
      "Tour Guide",
      "Fitness Trainer",
      "Yoga Instructor",
      "Actor",
      "Musician",
      "Photographer",
      "Film Director",
      "Sound Engineer",
      "Game Developer",
    ];
    const jobTitleOptions = jobTitles.map((title) => ({
      key: title,
      value: title,
    }));
    
    const handleInputChange = (e) => {
      const value = e.target.value;
      setFormData((prev) => ({ ...prev, jobTitle: value }));
  
      // Filter suggestions
      if (value.length > 0) {
        const filteredSuggestions = jobTitles.filter((title) =>
          title.toLowerCase().includes(value.toLowerCase())
        );
        setSuggestions(filteredSuggestions);
      } else {
        setSuggestions([]);
      }
    };

    const handleSuggestionClick = (suggestion) => {
      setFormData((prev) => ({ ...prev, jobTitle: suggestion }));
      setSuggestions([]); // Clear suggestions after selection
    };


  const handleEditClick = (event) =>{
  
    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;
    const modalHeight = 76; // Adjust based on your modal size
    const modalWidth = 140;

    // Calculate the top and left positions based on click coordinates
    const calculatedTop = Math.min(event.clientY, viewportHeight - modalHeight);
    const calculatedLeft = Math.min(event.clientX, viewportWidth - modalWidth);

    // Update position state
    setEditModalPosition({ top: calculatedTop, left: calculatedLeft });
    setEditModal(!editModal);
   

  }
  const statusOptions = [
    { key: "Draft", value: "Draft" },
    { key: "Pending", value: "Pending" },
    { key: "Activated", value: "Activated" },
    { key: "Denied", value: "Denied" },
 
  ];
  const approverOptions = responseData?.dropDown?.responders.map(responder => ({
    value: responder?.id,
    key: responder?.userName
  })) || [];

 
  const departmentOptions = [
   
      { key: "Human Resources", value: "Human Resources" },
      { key: "Finance and Accounting", value: "Finance and Accounting" },
      { key: "Marketing", value: "Marketing" },
      { key: "Sales", value: "Sales" },
      { key: "Operations", value: "Operations" },
      { key: "Information Technology", value: "Information Technology" },
      { key: "Research and Development", value: "Research and Development" },
      { key: "Customer Service and Support", value: "Customer Service and Support" },
      { key: "Legal", value: "Legal" },
      { key: "Administration", value: "Administration" },
      { key: "Strategy and Planning", value: "Strategy and Planning" },
      { key: "Procurement and Purchasing", value: "Procurement and Purchasing" },
      { key: "Health, Safety, and Environment", value: "Health, Safety, and Environment" },
      { key: "Quality Assurance", value: "Quality Assurance" },
      { key: "Public Relations and Corporate Communications", value: "Public Relations and Corporate Communications" },


    // "Software Development","IT","Finance"
  ];
  
  const handleFilter = (event) =>{
    setDropdown(!dropdown);
    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;
    const modalHeight = 100; // Adjust based on your modal size
    //const modalWidth = 140;

    // Calculate the top and left positions based on click coordinates
    const calculatedTop = Math.min(event.clientY, viewportHeight - modalHeight);
    const calculatedLeft = Math.min(event.clientX, viewportWidth - 140);

    // Update position state
    setFilterModalPosition({ top: calculatedTop, left: calculatedLeft });
  }

  const handleFilterSubmit = (key,value)=>{
    setDropdown(false)
    setResponseData({
      ...responseData,   // Spread the previous state to keep other properties
      meta: {
        ...responseData.meta,  // Spread the existing meta to keep other meta properties
        currentPage: 1      // Update only the currentPage
       
      }
    });
  
    // const objList = {
    //     // "status": a?.join(","),
    //     "status": a?.length ? JSON.stringify(a) : "",  
    //     "department": b?.join(","),
    //     "approverId" : c?.join(","),
    //     // "associateId": d?.join(","),
    // };


    setFiltersList({
      ...filtersList,
      [key]:value?.join(',')
    });
  }

  const handleTitleClick = (data)=>{
    console.log("123",data?.vacancyStatus==='Pending');
    console.log(currentStatus,"4567")
    if(data?.vacancyStatus==='Draft'){
      console.log("456","KEYCHECK")
      setFormData({
        jobTitle: data?.jobTitle || '',
        department: data?.department || '',
        qualification: data?.qualification || '',
        dateTime: data?.dateTime ? data?.dateTime : '',
        approver: data?.approver ?? '',
        minExperience: data?.minExperience || '',
        maxExperience: data?.maxExperience || '',
        minCompensation: data?.minCompensation || '',
        maxCompensation: data?.maxCompensation || '',
        approverName: data?.approverName || '-',
        reasonDescription: data?.reasonDescription || '',
        priority: data?.priority ?? '',
        vacancies: data?.vacancies || '',
        vacancyStatus: data?.vacancyStatus || '',
        draft : data?.draft || null,
        id: data?.id || null,
        vacancyReason: data?.vacancyReason || null,
        activities: data?.activities || null,
        anticipatedJoinDate: data?.anticipatedJoinDate? data?.anticipatedJoinDate : '',
        currency: data?.currency || '',
        compensationStructure: data?.compensationStructure || '',

        
      });
      console.log(formData,"456")
      setCreatorFormModal(true)
    }
    else if(data?.vacancyStatus==='Pending' || data?.vacancyStatus==='Denied'){
      console.log("123","INHERE")
      setFormData({
        jobTitle: data?.jobTitle || '',
        department: data?.department || '',
        qualification: data?.qualification || '',
        dateTime: data?.dateTime ? data?.dateTime : '',
        approver: data?.approver ?? '',
        minExperience: data?.minExperience || '',
        maxExperience: data?.maxExperience || '',
        minCompensation: data?.minCompensation || '',
        maxCompensation: data?.maxCompensation || '',
        approverName: data?.approverName || '-',
        reasonDescription: data?.reasonDescription || '',
        priority: data?.priority ?? '',
        vacancies: data?.vacancies || '',
        vacancyStatus: data?.vacancyStatus || '',
        draft : data?.draft || null,
        id: data?.id || null,
        vacancyReason: data?.vacancyReason || null,
        activities: data?.activities || null,
        anticipatedJoinDate: data?.anticipatedJoinDate? data?.anticipatedJoinDate : '',
      });
      // if(data?.vacancyStatus==='Denied'){
      //   setEditFormModal(true);
      // }
      setViewFormModal(true)
    }
    else if(data?.vacancyStatus==='Activated'){
      console.log("456","KEYCHECK")
      setFormData({
        jobTitle: data?.jobTitle || '',
        department: data?.department || '',
        qualification: data?.qualification || '',
        dateTime: data?.dateTime? data?.dateTime : '',
        approver: data?.approver ?? '',
        minExperience: data?.minExperience || '',
        maxExperience: data?.maxExperience || '',
        minCompensation: data?.minCompensation || '',
        maxCompensation: data?.maxCompensation || '',
        approverName: data?.approverName || '-',
        reasonDescription: data?.reasonDescription || '',
        priority: data?.priority ?? '',
        vacancies: data?.vacancies || '',
        vacancyStatus: data?.vacancyStatus || '',
        draft : data?.draft || null,
        id: data?.id || null,
        vacancyReason: data?.vacancyReason || null,
        activities: data?.activities || null,
        anticipatedJoinDate: data?.anticipatedJoinDate? data?.anticipatedJoinDate : '',
      });
      console.log(formData,"456")
      setViewFormModal(true)
    }

  }
const handleEditFormClick = ()=>{
  setViewFormModal(false)
  setCreatorFormModal(true)
}

const handleClick = ()=>{
 console.log("CLICKED")
}

  const handleApproverChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      approver: value
    }));
  };
  const handleReasonChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      vacancyReason: value
    }));
  };
  const handleDepartmentChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      department: value
    }));
  };

  const handleCompensationChange = (value)=>{
    setFormData((prevFormData) => ({
      ...prevFormData,
      compensationStructure: value
    }));
  };
  const handleCurrencyChange = (value)=>{
    setFormData((prevFormData) => ({
      ...prevFormData,
      currency: value
    }));
  }
  const handlePriorityChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      priority: value
    }));
  };
  

  const handleRecruitmentChange = (value)=>{
    setFormData((prevFormData)=>({
      ...prevFormData,
      recruitmentType: value
    }))
  }

  const handleNumberInputChange = (value, type)=>{
    
    switch(type) {
      case 'minExperience':
        
        setFormData((prevState) => {
          let newState = { ...prevState, [type]: value };
      
          // If the minimum experience is being updated, also update the maximum experience if necessary
         
            if (!prevState.maxExperience || prevState.maxExperience < value) {
              // Automatically set maxExperience to match minExperience
              newState.maxExperience = value;
            }
          
      
          return newState;
        });
   
        break;
        
      case 'maxExperience':
        setFormData(prevState => ({
          ...prevState,
          maxExperience: value, 
        }));
        break;
  
      case 'minCompensation':
        setFormData((prevState) => {
          let newState = { ...prevState, [type]: value };
      
          // If the minimum experience is being updated, also update the maximum experience if necessary
         
            if (!prevState.maxCompensation || prevState.maxCompensation < value) {
              // Automatically set maxExperience to match minExperience
              newState.maxCompensation = value;
            }
          
      
          return newState;
        });
        break;
        case 'vacancies':
        setFormData(prevState => ({
          ...prevState,
          vacancies: value,
        }));
        break;
  
      case 'maxCompensation':
        setFormData(prevState => ({
          ...prevState,
          maxCompensation: value, 
        }));
        break;
        
      default:
        console.log('Unknown type:', type); // Handle unrecognized types
    }
  }

  const handleDelete = ()=>{
    setEditModal(false)
    console.log("DELETE")
    
    deleteVacancyRequest(editFormModal?.id,(response,error)=>{
      if (error) {
        console.error("Error deleting vacancy:", error);
      } else {
        console.log("Vacancy deleted successfully:", response);
  
        // setCount(response?.count)
        // setSearch('');
        getRequests()
      
      }
    });
  }

  const handleStatusCardClick = (value,name)=>{
    console.log(name,"EDITACTIVATE")
    let params = {
      jobId: value,
      active: name==="Activate"?true: name==="Deny"?false:null,
    }
    updateVacancyState(params,(response,error)=>{
      if(error){
        console.error();
      }
      else{
        console.log(response,"Vacancy request updated");
        getRequests();
      }
    })
  }

  useEffect(()=>{
    console.log(formData,'EXPCHECK')
  },[formData])


  const handleDept=(v)=>{
  console.log(v)
  }


  const columns=[
    {
      meta:{
        title:'Job Title',
        type:'dropdown',
        styles:{
           width:'200px',
           
        },
        options: jobTitleOptions,
        isMultiSelect: true,
        selectAll:true
     
      },
      handler:(e)=>{setJobTitle(e)}
     
    },
   
    {
      meta:{
        title:'Date & Time',
        type:'sort',
        styles:{
          width:'180px'
        },
      },
      handler: (e)=>{setSortDateTime(e)}
    },
    {
      meta:{
        title:'Department/Category',
        type:'dropdown',
        styles:{
          width:'160px'
        },
        options: departmentOptions,
        isMultiSelect: true,
        selectAll:true
        
      },
      handler:(a)=>{handleFilterSubmit("department",a)}

    },
    {
      meta:{
        title:vacancyCreatorFlag?'Approver':'Requested by',
        type:'dropdown',
        styles:{
          width:'160px'
        },
        options: approverOptions,
        isMultiSelect: true
      },
      handler:(a)=>{handleFilterSubmit("approverId",a)}

    },
    {
      meta:{
        title:'Status',
        type:'dropdown',
        styles:{
          width:'180px'
        },
        options: statusOptions,
        isMultiSelect: true
      },
      handler:(a)=>{handleFilterSubmit("status",a)}

    },
    {
      meta:{
        title:'',
        type:'default',
        styles:{
          width:'50px',
          maxWidth:'50px'
        },
      }
    },
    
  ]
  if (!vacancyCreatorFlag) {
    columns.splice(
      columns.length - 2, // Start from the last two elements
      2, // Remove the last two entries
      {
        meta: {
          title: 'Actions',
          type: 'default',
          styles: {
            width: '280px'
          }
        }
      },
      
    );
  }
  
  
  const data= responseData?.vacancies?.map(data=>{
     const row =[
        {renderItem: <div style={{color:'#1890FF',cursor:'pointer'}} onClick={()=>{
                          
          setCurrentStatus(data?.vacancyStatus);
          console.log(data,"KEYCHECK")
          setEditFormData(true)
          handleTitleClick(data);
       
        }}>{data?.jobTitle}</div>},
        {renderItem: <div>
          <div>
            <div>{data?.vacancyStatus==='Draft'?'Created on':data?.vacancyStatus==='Pending'?vacancyCreatorFlag?'Requested on':'Recieved on':data?.vacancyStatus==='Denied'?'Denied on':'Activated on'}</div>
            <div>{data?.dateTime}</div>
          </div>
        </div>},
        {renderItem:<div>
          {/* <div>{data?.department}</div> */}
          <div>{data?.department?data?.department:'-'}</div>
        </div>},
        {renderItem:<div >
          <div>{data?.approverName?data?.approverName:'-'}</div>
        </div>},
   
        {renderItem: <div><StatusCard name={data?.vacancyStatus} index={data?.id} type=""/></div>},
        {renderItem:<div >
          <img src={editTable} alt="editOptions"  style={{cursor:'pointer'}}
            onClick={(event)=>{
              setCurrentStatus(data?.vacancyStatus)
              // setEditFormData(data);
              setEditFormModal(data)
              handleEditClick(event)}}
          />
          </div>},
      ];
      
       
       if (!vacancyCreatorFlag) {
        // Remove the last two items and replace them with a different item
        row.splice(
          -2, // Start from the second-to-last item
          2,  // Remove two items
          {
            renderItem: (
              <div>
              
                {(data?.vacancyStatus==="Pending")&&(
        <div className="table-cellR" style={{display:'flex', flexDirection:'row', padding:'0px',borderBottom:'none',width:'240px',overflow:'inherit',gap:'8px'}}>
            <StatusCard name="Activate" index={data?.id} type="Responder" onClick={(value,name)=>{handleStatusCardClick(value,name)}}/>
            <StatusCard name="Deny" index={data?.id} type="Responder" onClick={(value,name)=>{handleStatusCardClick(value,name)}} />
          </div>)}
         {(data?.vacancyStatus==="Denied")&&(<StatusCard name="Denied" index={data?.id} type="Responder"/>)}
         {(data?.vacancyStatus==="Activated")&&(<StatusCard name="Activated" index={data?.id} type="Responder"/>)}
              </div>
            ),
          }
        );
      }
    
      return row;
    })


    return (
      <>
      
          {(vacancyCreatorFlag===true && responseData?.vacancies?.length>0 )?
          <div className="requests-table-container">
            <div className="requests-table-subcontainer" >
              <SearchCompo onSearch={(value)=>{
                setResponseData( {
                  ...responseData,   // Spread the previous state to keep other properties
                  meta: {
                    currentPage: 1 ,
                    itemsPerPage:10     // Update only the currentPage
                  }
                });
  
                setSearch(value)
              }}  /> 
              <div className="table-subcontainer" >
              <Table columns={columns} rowData={data} loading={loading}/>
              
              </div>
              <Pagination 
              totalPages={responseData?.meta?.totalPages}
              currentPage={responseData?.meta?.currentPage}
              onPageChange={handlePageChange} 
              itemsPerPage={responseData?.meta?.itemsPerPage}
              onItemsPerPageChange={handleItemsPerPageChange}
             />
            </div>
            <div>
               <OverviewCard/>
            </div>
      
          </div>
          :
          vacancyCreatorFlag===true && search===null && filtersList===null?
          <div className="dashed-box">
            <div className="dashed-box-child" > 
                <div><img src={RequestsIcon} alt="icon"/></div>
                <div>It's time to find the right talent</div>
                <div>Click 'Raise a Request' to start hiring and manage the hiring process from here</div>
            </div>
            <button id="raiseReqForm" type="button" style={{cursor:'pointer'}}  onClick={toggleReqForm} className="dashed-box-button"> 
              <img src={addButtonIcon} />
              <div>Raise a request</div>
            </button>
          </div>
          :
          responseData?.vacancies?.length>0 && vacancyCreatorFlag===false?
           <div className="requests-table-container">
           <div className="requests-table-subcontainer" >
             <SearchCompo onSearch={(value)=>{
              setResponseData({
                ...responseData,
                meta:{
                  currentPage:1,
                  itemsPerPage:10
                }
              })
           
               setSearch(value)
             }}/> 
             <div className="table-subcontainer">
             <Table columns={columns} rowData={data} loading={loading}/>
           
             </div>
             <Pagination 
            
             totalPages={responseData?.meta?.totalPages}
             currentPage={responseData?.meta?.currentPage}
             onPageChange={handlePageChange} 
             itemsPerPage={responseData?.meta?.itemsPerPage}
             onItemsPerPageChange={handleItemsPerPageChange}
            />
           </div>
           <div>
              <OverviewCard/>
           </div>
     
           </div>
          :vacancyCreatorFlag==false &&responseData?.vacancies?.length===0?
            <div className="dashed-box">
            <div className="dashed-box-child" > 
                <div><img src={RequestsIcon} alt="icon"/></div>
                <div>It’s empty in here!</div>
                <div>No request has been submitted at this time.</div>
            </div>
            </div>
            :responseData?.vacancies===undefined?
            <div className="requests-table-container">
            <div className="requests-table-subcontainer" >
              <SearchCompo onSearch={(value)=>{
                setResponseData( {
                  ...responseData,   // Spread the previous state to keep other properties
                  meta: {
                    currentPage: 1 ,
                    itemsPerPage:10     // Update only the currentPage
                  }
                });
  
                setSearch(value)
              }}  /> 
              <div className="table-subcontainer" >
              <Table columns={columns} rowData={data} loading={loading}/>
              
              </div>
              <Pagination 
              totalPages={responseData?.meta?.totalPages}
              currentPage={responseData?.meta?.currentPage}
              onPageChange={handlePageChange} 
              itemsPerPage={responseData?.meta?.itemsPerPage}
              onItemsPerPageChange={handleItemsPerPageChange}
             />
            </div>
            <div>
               <OverviewCard/>
            </div>
      
          </div>
            
           
            :
            <div className="requests-table-container">
              <div className="requests-table-subcontainer">
                <SearchCompo onSearch={(value)=>{
                  setResponseData({
                    ...responseData,
                    meta:{
                      currentPage:1,
                      itemsPerPage:10
                    }
                  })
                
                  setSearch(value)
                }}  /> 
              
                  <div className="table-subcontainer" >
                  <Table columns={columns} rowData={data} loading={loading}/>
                  <Pagination 
              totalPages={responseData?.meta?.totalPages}
              currentPage={responseData?.meta?.currentPage}
              onPageChange={handlePageChange} 
              itemsPerPage={responseData?.meta?.itemsPerPage}
              onItemsPerPageChange={handleItemsPerPageChange}
             />
                      
                    {/* <div className="noData-container"  >
                      <img src={noDataIcon} alt="NoDataIcon"/>
                        <div>OOPS! No results</div>
                        <div>We couldn't find any matches</div>
                    </div> */}
                    
                  
                
                  </div>
                 
              </div>
              <div>
                <OverviewCard/>
              </div>
            </div>
          }
        
        
        {(creatorFormModal) && (
          <div className="req-modal-overlay" onClick={handleOutsideClick}>
              <div className="req-modal-content">
                {(editFormData===false )?
                  <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                    <div>
                      <div>Raise a Request</div>
                      <div>Provide necessary information to create a request and forward it for approval inorder to activate the job vacancy</div>
                    </div>
                    <div><img src={closeIcon} alt="closeIcon" style={{cursor:'pointer'}} onClick={toggleForm}/></div>
                  </div>
                  :
                  editFormData===true ?
                  <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                    <div>Edit Request</div>
                    <div style={{cursor:'pointer'}} onClick={toggleForm}> <img src={closeIcon} alt="closeIcon"/></div>
                  </div>
                  :
                  null

                }
                <form className="req-modal-form" onSubmit={handleSubmit}>
                      <div className="req-form-subtitle">
                        <div style={{width:'4px', height:'1.5rem',backgroundColor:'#2337C6'}}></div>
                        <div className="form-title">Basic Job Info</div>
                      </div>
                      

                      <div className="form-group">
                         
                          <div className="input-group">
                            <label htmlFor="jobTitle">Job Title <span style={{color:'#F5222D'}}>*</span></label>
                            
                            <AutoComplete
                              options={options}
                              onSearch={handleSearch}
                              onSelect={handleSelect}
                              placeholder="Enter a Job Title"
                              style={{ width: "100%" }}
                              value={formData?.jobTitle}
                           
                            >
                              <Input isRequired/>
                            </AutoComplete>
                          </div>


                          {/* <div className="input-group">
                              <label htmlFor="department">Department/Category</label>
                              <input
                                  type="text"
                                  id="department"
                                  name="department"
                                  placeholder="Select Department/Category"
                                  value={formData?.department}
                                  onChange={handleChange}
                                  autoComplete="off"
                              />
                          </div> */}
                         <SearchableSelect options={departments}  placeholder="Select Department/Category" onChange={(e)=>{handleDepartmentChange(e)}} title="Department/Category" inputVal={formData?.department||'-'}/>

                      </div>

                      <div className="form-group">
                          <div className="input-group">
                              <label htmlFor="qualifications">Qualifications</label>
                              <input
                                  type="text"
                                  id="qualifications"
                                  name="qualification"
                                  value={formData?.qualification}
                                  onChange={handleChange}
                                  placeholder="e.g., Bachelor’s in Computer Science"
                                  autoComplete="off"
                              />
                          </div>                        
                            <MultiSearchSelect/>
                          {/* <NumberInputExample title="Minimum years of experience" min={0} max={100} onChange={(value)=>{handleNumberInputChange(value,"minExperience")}} inputVal={formData?.minExperience} /> */}
                          
                      </div>

                      <div className="form-group">
                        <NumberInputExample title="Minimum years of experience" min={0} max={100} onChange={(value)=>{handleNumberInputChange(value,"minExperience")}} inputVal={formData?.minExperience} />
                        <NumberInputExample title="Maximum Years of Experience" min={formData?.minExperience} max={100} onChange={(value)=>{handleNumberInputChange(value,"maxExperience")}} inputVal={formData?.maxExperience}/>
                          
                      </div>

                          <hr style={{ border: '1px solid #E9E9E9',marginBottom:'1.5rem'}}></hr>

                          <div className="req-form-subtitle">
                              <div style={{width:'4px', height:'1.5rem',backgroundColor:'#2337C6'}}></div>
                              <div className="form-title">Justification</div>
                          </div>

                          <div className="form-group">
                            <SearchableSelect options={reasonOptions} placeholder="Select reason for vacancy" onChange={(e)=>{handleReasonChange(e)}} title="Reason for vacancy" inputVal={formData?.vacancyReason}/>

                         
                          </div>
                          <div className="form-group">
                            <div className="input-group">
                                <label htmlFor="description">Description</label>
                                <input
                                    type="text"
                                    id="description"
                                    name="reasonDescription"
                                    placeholder="Expand on the reason"
                                    className="description"
                                    value={formData?.reasonDescription}
                                    onChange={handleChange}
                                    style={{alignItems:'flex-start', boxSizing:"border-box",height:'4rem'}}
                                    AutoComplete="off"
                                />
                            </div>
                          </div>

                      <hr style={{ border: '1px solid #E9E9E9',marginBottom:'1.5rem'}}></hr>
                      

                      <div className="req-form-subtitle">
                        <div style={{width:'4px', height:'1.5rem',backgroundColor:'#2337C6'}}></div>
                        <div className="form-title">Compensation</div>
                      </div>
                    
                    <div className="form-group">
                    <SearchableSelect options={currencies} placeholder="₹" onChange={(e)=>{handleCurrencyChange(e)}} title="Currency" inputVal=''/>
                    <SearchableSelect options={compensationStructureOptions} placeholder="Per Annum" onChange={(e)=>{handleCompensationChange(e)}} title="Compensation structure" inputVal=''/>
                    </div>

                      <div className="form-group">
                          <NumberInputExample title="Minimum range" min={0} max={100000000} onChange={(value)=>{handleNumberInputChange(value,"minCompensation")}} inputVal={formData?.minCompensation}/>
                          <NumberInputExample title="Maximum range" min={formData?.minCompensation} max={100000000} onChange={(value)=>{handleNumberInputChange(value,"maxCompensation")}} inputVal={formData?.maxCompensation}/>
                      </div>

                      <hr style={{ border: '1px solid #E9E9E9',marginBottom:'1.5rem'}}></hr>

                      <div className="req-form-subtitle">
                        <div style={{width:'4px', height:'1.5rem',backgroundColor:'#2337C6'}}></div>
                        <div className="form-title">Preferences</div>
                      </div>
                      

                      <div className="form-group">
                          <SearchableSelect options={priorityOptions} placeholder="Select a priority" onChange={(value)=>{handlePriorityChange(value)}} title="Priority" inputVal={formData?.priority??''}/>
                          <NumberInputExample title="Number of vacancies" min={0} max={10} onChange={(value)=>{handleNumberInputChange(value,"vacancies")}} inputVal={formData?.vacancies}/>
                      </div>
        
                      
                      <div className="form-group">
                        <div className="input-group">
                          <label>Anticipated joining date</label>
                          <MyDatePicker
                            date={
                              editFormData
                                ? formData?.anticipatedJoinDate
                                ? formData?.anticipatedJoinDate
                                : null
                              :null
                            }
                            handleAnticipatedDate={(value)=>{                              
                              const date = new Date(value);
                              const formattedDate = `${date.getFullYear()}/${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')}`;
                             
                              setFormData(prevState => ({
                                ...prevState,
                                anticipatedJoinDate: formattedDate, 
                              }));
                            }}
                            
                          />  
                        </div>
                        <div className="input-group"></div>
                      </div>

                      <hr style={{ border: '1px solid #E9E9E9',marginBottom:'1.5rem'}}></hr>

                      <div className="req-form-subtitle">
                        <div style={{width:'4px', height:'1.5rem',backgroundColor:'#2337C6'}}></div>
                        <div className="form-title">Approval Chain</div>
                      </div>
                      

                      <div className="form-group">                        
                        <SearchableSelect options={responderOptions} placeholder="Anyone" title="Approver"  onChange={handleApproverChange} inputVal={formData?.approver??''}/>
                        <div className="input-group"></div>
                      </div>

                      {
                        <div className="all-buttons">
                          {(
                            (editFormData===false ||formData?.vacancyStatus==='Draft')
                            && 
                          (formData?.vacancyStatus!=="Pending" || formData?.vacancyStatus!=="Denied" ))?
                          <div>
                            <button className="close-btn" onClick={toggleForm}>Close</button>
                          </div>
                          :
                          <div>
                            <button className="close-btn" onClick={()=>{
                              setCreatorFormModal(false)
                              setResponderFormModal(false)
                              handleTitleClick(formData)
                            }}>Go back</button>
                          </div>
                          }
                          {editFormData===false || formData?.vacancyStatus ==="Draft" || formData?.vacancyStatus==="Denied"?
                            <div>
                            <button type="submit" className="draft-button" onClick={()=>{
                              setSubmitAction('draft')
                            
                            }}>Save as draft
                            </button>
                            <button type="submit" className="submit-button" onClick={()=>{
                              setSubmitAction('raiseRequest')}}>
                                Raise a request
                            </button>
                            </div>
                            :editFormData===true && formData?.vacancyStatus === "Pending"?
                            <div>
                              <button  type="submit" className="submit-button">Save</button>
                            </div>
                            :null 
                          }
                        </div>
                       
                      }
                </form>
              </div>
          </div>
        )}
        {(responderFormModal) && (
          <div className="req-modal-overlay-responder" onClick={handleOutsideClick}>
              <div className="req-modal-content-responder">
                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                    <div>
                      <div>Create Job Vacancy</div>
                      <div>Complete the fields to create a job vacancy and begin your search for ideal hire</div>
                    </div>
                    <div>
                      <img src={closeIcon} alt="closeIcon" onClick={toggleFormResponder} style={{cursor:'pointer'}}/>
                    </div>
                </div>
              
                <form className="req-modal-form-responder" onSubmit={handleSubmit}>              

                      <div className="form-group-responder">
                          
                          <div className="input-group">
                            <label htmlFor="jobTitle">Job Title<span style={{color:'#F5222D'}}>*</span></label>
                            <AutoComplete
                              options={options}
                              onSearch={handleSearch}
                              onSelect={handleSelect}
                              placeholder="Enter a Job Title"
                              style={{ width: "100%" }}
                              value={formData?.jobTitle}
                              
                            >
                              <Input isRequired/>
                            </AutoComplete>
                          </div>
       
                      </div>
                      <div className="form-group-responder">
                          <div className="input-group-responder">
                              <label htmlFor="JobCode">Job Code</label>
                              <input
                                  type="text"
                                  id="jobcode"
                                  name="jobCode"
                                  value={formData?.jobCode}
                                  placeholder="JV_2024/35"
                                  onChange={handleChange}
                              />
                          </div>
                      </div>
                      <div className="form-group-responder">
                        {/* <div className="input-group-responder">
                                <label htmlFor="department">Department/Category</label>
                                <input
                                    type="text"
                                    id="department"
                                    name="department"
                                    placeholder="Select Department/Category"
                                    value={formData?.department}
                                    onChange={handleChange}
                                />
                            </div> */}
                   <SearchableSelect options={departments}  placeholder="Select Department/Category" onChange={(e)=>{handleDepartmentChange(e)}} title="Department/Category" inputVal={formData?.department||'-'}/>


                      </div>
                      {/* <SearchableSelect options={departments}  placeholder="Select Department/Category" onChange={(e)=>{handleDepartmentChange(e)}} title="Department/Category" inputVal={formData?.department||'-'}/> */}

                          <div className="form-group-responder">
                            <SearchableSelect options={recruitmentTypeOptions} placeholder="Select recruitment type"  title="Recruitment type" inputVal='' onChange={handleRecruitmentChange} isRequired={true}/>
                          </div>
                      <div className="all-buttons-responder">
                        <button className="close-btn-responder" onClick={toggleFormResponder}>Close</button>
                        <button type="submit" className="submit-button-responder" onClick={()=>{
                          setSubmitAction('createRequest')                      
                          }}>Create
                        </button>                   
                      </div>
  
                </form>
              </div>
          </div>
        )}
        {editModal && (
            <div 
              className="editModal-container" 
              style={{
                top: `${editModalPosition.top + 10}px`, 
                left: `${editModalPosition.left + 10}px`,
              }}
            >
              {currentStatus === 'Draft'||  currentStatus === 'Pending' ? (
                <>
                  <div  className="hover-item" onClick={()=>{
                      setEditModal(false)
                      setEditFormData(true)
                      handleTitleClick(editFormModal);
                    }} >
                    <img src={EditIcon} alt="EditIcon" />
                    Edit
                  </div>
                  <div  className="hover-item" onClick={handleDelete} >
                    <img src={deleteIcon} alt="DeleteIcon" />Delete
                  </div>
                </>
              ) :  currentStatus === 'Denied' ? (
                <div  className="hover-item" onClick={()=>{
                  setEditModal(false)
                  setEditFormData(true)
                  handleTitleClick(editFormModal);
                }}>
                  <img src={EditIcon} alt="EditIcon"  />
                  Edit
                </div>
              ) : currentStatus === 'Activated' ? (
                <div  className="hover-item" onClick={()=>{
                  setEditModal(false);
                  handleViewVacancyClick(editFormModal?.id)
                  
                }}>
                  <img src={viewIcon} alt="viewIcon" />View vacancy
                </div>
              ) : null}
            </div>
        )}
        {(viewFormModal) && (
          <div className="req-modal-overlay" onClick={handleOutsideClick}>
          <div className="req-modal-content" >
            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
              <div>Request</div>
              <div style={{cursor:'pointer'}}><img src={closeIcon} alt="closeIcon" onClick={toggleForm}/></div>
            </div>
          
            <form className="req-modal-form" onSubmit={handleSubmit}>
                  <div className="req-form-subtitle">
                    <div style={{width:'4px', height:'1.5rem',backgroundColor:'#2337C6'}}></div>
                    <div className="form-title">Basic Job Info</div>
                  </div>
                  

                  <div className="form-group">
                      <div className="input-group">
                          <label htmlFor="jobTitle">Job Title</label>
                          
                          <div style={{color:'#1F1F1F'}}>{formData?.jobTitle||'-'}</div>
                      </div>

                      <div className="input-group">
                          <label htmlFor="department">Department/Category</label>
                         <div style={{color:'#1F1F1F'}}>{formData?.department||'-'}</div>
                      </div>
                  </div>

                  <div className="form-group">
                      <div className="input-group">
                          <label htmlFor="qualifications">Qualifications</label>
                          <div style={{color:'#1F1F1F'}}>{formData?.qualification||'-'}</div>
                      </div>
                      <div className="input-group">
                          <label htmlFor="Specializations">Specializations</label>
                          <div style={{color:'#1F1F1F'}}>{formData?.qualification||'-'}</div>
                      </div>


                      
                  </div>

                  <div className="form-group">
                      <NumberInputExample title="Minimum years of experience" min={0} max={100} onChange={(value)=>{handleNumberInputChange(value,"minExperience")}} inputVal={formData?.minExperience}/>

                      <NumberInputExample title="Maximum Years of Experience" min={formData?.minExperience} max={100} onChange={(value)=>{handleNumberInputChange(value,"maxExperience")}} inputVal={formData?.maxExperience}/>
                      
                  </div>

                      <hr style={{ border: '1px solid #E9E9E9',marginBottom:'1.5rem'}}></hr>

                      <div className="req-form-subtitle">
                          <div style={{width:'4px', height:'1.5rem',backgroundColor:'#2337C6'}}></div>
                          <div className="form-title">Justification</div>
                      </div>

                      <div className="form-group">
                        <SearchableSelect options={reasonOptions} placeholder="Select reason for vacancy" onChange={(e)=>{handleReasonChange(e)}} title="Reason for vacancy" inputVal={formData?.vacancyReason||'-'}/>

                     
                      </div>
                      <div className="form-group">
                        <div className="input-group">
                            <label htmlFor="description">Description</label>
                            
                             <div style={{color:'#1F1F1F'}}>{formData?.reasonDescription||'-'}</div>

                        </div>
                      </div>

                  <hr style={{ border: '1px solid #E9E9E9',marginBottom:'1.5rem'}}></hr>
                  

                  <div className="req-form-subtitle">
                    <div style={{width:'4px', height:'1.5rem',backgroundColor:'#2337C6'}}></div>
                    <div className="form-title">Compensation</div>
                  </div>
                  <div className="form-group">
                   <div className="input-group">
                    <label>Currency</label>
                    <div style={{ color: '#1f1f1f' }}> ₹</div>
                   </div>
                   <div className="input-group">
                    <label>Compensation Structure</label>
                    <div style={{ color: '#1f1f1f' }}>Per Annum</div>
                   </div>
                  </div>

                  <div className="form-group">             
                      <NumberInputExample title="Minimum range" min={0} max={100000000} onChange={(value)=>{handleNumberInputChange(value,"minCompensation")}} inputVal={formData?.minCompensation}/>
                      <NumberInputExample title="Maximum range" min={formData?.minCompensation} max={100000000} onChange={(value)=>{handleNumberInputChange(value,"maxCompensation")}} inputVal={formData?.maxCompensation}/>
                  </div>

                  <hr style={{ border: '1px solid #E9E9E9',marginBottom:'1.5rem'}}></hr>

                  <div className="req-form-subtitle">
                    <div style={{width:'4px', height:'1.5rem',backgroundColor:'#2337C6'}}></div>
                    <div className="form-title">Preferences</div>
                  </div>

                  <div className="form-group">                     
                      <SearchableSelect options={priorityOptions} placeholder="Select a priority" title="Priority" onChange={(e)=>{handlePriorityChange(e)}} inputVal={formData?.priority??''}/>
                      <NumberInputExample title="Number of vacancies" min={0} max={10} onChange={(value)=>{handleNumberInputChange(value,"vacancies")}} inputVal={formData?.vacancies}/>
                  </div>
                 
                  <div className="form-group">
                    <div className="input-group">
                    <label>Anticipated joining date</label>
                    {(formData?.anticipatedJoinDate?
                    <div style={{ color: '#1f1f1f' }}>
                    {formData?.anticipatedJoinDate.split("/").reverse().join("/")}
                  </div>
                  :<div style={{color:'#1f1f1f'}}>{"-"}</div>)}
                    </div>
                    <div></div>
                  </div>

                  <hr style={{ border: '1px solid #E9E9E9',marginBottom:'1.5rem'}}></hr>

                  {vacancyCreatorFlag?
                  <>
                  <div className="req-form-subtitle">
                    <div style={{width:'4px', height:'1.5rem',backgroundColor:'#2337C6'}}></div>
                    <div className="form-title">Approval Chain</div>
                  </div>
                  

                  <div className="form-group">
                    
                    <SearchableSelect options={responderOptions} placeholder="Anyone" title="Approver"  onChange={handleApproverChange} inputVal={formData?.approver??''}/>
                    <div className="input-group"></div>
                    
                  </div>
                  </>
                  :
                  <>
                  <div className="req-form-subtitle">
                    <div style={{width:'4px', height:'1.5rem',backgroundColor:'#2337C6'}}></div>
                    <div className="form-title">Activities</div>
                  </div>

                  <div className="form-group">                 
                    <CustomTimeline options={formData?.activities} onLoadMore={()=>{

                    }}
                    type="form-activities"
                    />
                    
                  </div>
                  </>
                  }

                  <div className="all-buttons">
                  {vacancyCreatorFlag?
                  <>
                  <div>
                    <button className="close-btn" onClick={toggleForm}>Close</button>
                  </div>
                  
                  <div>
                    {formData?.vacancyStatus==='Pending'||formData?.vacancyStatus==='Denied'?
                    <button type="submit" className="draft-button" onClick={()=>{
                      handleEditFormClick()
                      setEditFormData(true)
                      
                     }}>Edit
                     </button>: formData?.vacancyStatus==='Activated'?
                    <button type="submit" className="draft-button" onClick={()=>{
                     toggleForm()
                     handleViewVacancyClick(formData?.id)

                     
                    }}>View vacancy
                    </button>:null
}
                  </div>
                  </>
                  :
                  vacancyCreatorFlag===false && formData?.vacancyStatus==="Activated"?
                  <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'100%'}}>
                    <div><button  className="close-btn" onClick={toggleForm}>Close</button></div>
                    <div ><button className="draft-button"  onClick={() => {
                                toggleForm();
                                handleViewVacancyClick(formData?.id);
                              }}
                              >View vacancy</button></div>
                  </div>
                  :vacancyCreatorFlag===false && formData?.vacancyStatus === "Pending" ? (
                    <div
                      className="table-cellR"
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '0px',
                        borderBottom: 'none',
                        width: '100%',
                        overflow: 'inherit',
                        justifyContent:'space-between'
                      }}
                    >
                      <div>
                      
                          <button
                            type="submit"
                            className="draft-button"
                            onClick={handleEditFormClick}
                          >
                            Edit
                          </button>
                        
                      
                        </div >
                        <div style={{display:'flex', flexDirection:'row', gap:'16px'}}>
                      <StatusCard
                        name="Deny"
                        index={formData?.id}
                        type="Responder-edit"
                        onClick={(value, name) => handleStatusCardClick(value, name)}
                        
                      />
                      <StatusCard
                        name="Activate"
                        index={formData?.id}
                        type="Responder-edit"
                        onClick={(value, name) => handleStatusCardClick(value, name)}
                      />
                      </div>
                    </div>
                  ):vacancyCreatorFlag===false && formData?.vacancyStatus === "Denied"?(
                    <div>
                      <button className="close-btn" onClick={toggleForm}>Close</button>
                    </div>
                  ):null
                
                  }
              </div>

              

                  
            </form>
            
          
          </div>
      </div>
        )}
      </>
    );
  }

export default CreateRequests;