import Api from "../../../service/Api"

export const getNotifications = (params,callback) => {
  
    Api.get('/api/hiring/getNotifications').params(params)
    .send((response, error) => {
        callback(response,error)
      })
}

export const getNotificationsAlertsCount = (params,callback) => {
  
    Api.get('/api/hiring/notificationAlertsCount').params(params)
    .send((response, error) => {
        callback(response,error)
      })
}

export const updateNotificationsAlertsCount = (params,callback) => {
  
    Api.patch('/api/hiring/updatenotificationAlertsCount').params(params)
    .send((response, error) => {
        callback(response,error)
      })
}



export const notificationAction = (type,endpoint,params,callback) => {
  console.log("called",type,endpoint)
 
    Api?.[type]?.(endpoint).params(params)
    .send((response, error) => {
        callback(response,error)
      })
 
}