import { Modal, Form, DatePicker, Select, Input, Button, Row, Col, Divider, Space, InputNumber } from "antd"
import styles from "../recruitmentOverview/recruit.module.css"
import { updateVacancyRequests } from "../../Action/action";
import { useVacancyStore } from "../../store/store";
import dayjs from 'dayjs';
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';
const convertDate = (date) => {
    const jsDate = new Date(date);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = new Intl.DateTimeFormat('en-CA', options).format(jsDate);
    
    return formattedDate.replace(/-/g, '/'); // Replace dashes with slashes for the desired format
  };
export const arr = [
    {
        id: 1,
        label: "Job title",
        type: "input",
        name:"jobTitle"
    },
    {   
        id: 2,
        label: "Job code",
        type: "input",
        name:"jobCode"

    },
    {
        id: 3,
        label: "Job type",
        type: "dropdown",
        value: ["Full-time", "Part-time", "Contract","Freelance","Internship"],
        name:"jobType"
    },
    {
        id: 3,
        label: "Department/Category",
        type:"dropdown",
        name:'department',
        value:[
            "Human Resources",
            "Finance and Accounting",
            "Marketing",
            "Sales",
            "Operations",
            "Information Technology",
            "Research and Development",
            "Customer Service and Support",
            "Legal",
            "Administration",
            "Strategy and Planning",
            "Procurement and Purchasing",
            "Health, Safety, and Environment",
            "Quality Assurance",
            "Public Relations and Corporate Communications"
          ]
    },
    {
        id: 4,
        label: "Team",
        type: "input",
        name:"team"
    },
    {
        id: 5,
        label: "Job location",
        type: "input",
        name:"jobLocation"
    },
    {
        id: 6,
        label: "Work arrangements",
        type: "dropdown",
        value:["On-site","Remote","Hybrid"],
        name:"workArrangements",
       
    },
    {
        id: 7,
        label: "Qualifications",
        type: "input",
        name:"qualifications"
    },
    {
        id: 8,
        label: "Specializations",
        type: "input",
        name:"specializations"

    },
    {
        id: 9,
        label: "Certifications",
        type: "input",
        name:"certifications"
    },
    {
        id: 10,
        label: "Industry-specific experience",
        type: "dropdown",
        name:"industryExperince",
        value:["IT"]
    },
    {
        id: 11,
        label: "Minimum years of experience",
        type: "number",
        name:"minExperince"

    },
    {
        id: 12,
        label: "Maximum years of experience",
        type: "number",
        name:"maxExperince"
    },
    {
        id:13,
        label:"Travel involvement",
        type:"dropdown",
        name:"travel",
        value:["Yes","No","Maybe"]
    },
    {
        id:14,
        label:"Currency",
        type:"dropdown",
        name:"currency",
        value:["₹","$"]
    },
    {
        id:15,
        label:"Compensation structure",
        type:"dropdown",
        name:"compensationStructure",
        value:["Per annum","Per hour","Per project","Per task","Per milestone","Per diem","Per unit"]
    },
    {
        id: 16,
        label: "Minimum range",
        type: "number",
        name:"minCompensation"

    },
    {
        id: 17,
        label: "Maximum range",
        type: "number",
        name:"maxCompensation"
    }

]

const JobOverviewEditModal = (props) => {
 
    let datastored={...props.data}
    
  
    return (
        <Modal centered={true} open={props?.editModal} onCancel={() => { props.setEditModal(false) }} onClose={() => props.setEditModal(false)} width={"80%"} style={{ top: 5 }} footer={false}>
            <div style={{padding:20}}>
                <div className={styles.modalTitle} style={{marginBottom:16,fontSize:24}}>Edit Information</div>
                <Form  layout="vertical" 
                   
                onFinish={(values) => {
                
                
                    let obj={
                        jobId:datastored?.id,
                        ...values
                    }
                    console.log(" job overview values", obj, datastored)
              
                     updateVacancyRequests(obj,(res,err) =>{

                        props.fetchJobData({jobId:datastored?.id});
                        props.setEditModal(false);
            
                     })
                  
                }} >
                    <Row gutter={16}>

                        {arr?.map((ele, index) => (
                            <>
                             {index==7?<div className={styles.modalTitle} style={{marginLeft:8,width:"100%",display:"flex",flexDirection:"row"}}><div style={{height:20,width:4,backgroundColor:"#2337C6",marginRight:4}}></div>Job Requirements</div>
                                :index==14?<div className={styles.modalTitle} style={{marginLeft:8,width:"100%",display:"flex",flexDirection:"row"}}><div style={{height:20,width:4,backgroundColor:"#2337C6",marginRight:4}}></div>  Compensation</div>
                                :index===0?<div className={styles.modalTitle} style={{marginLeft:8,width:"100%",display:"flex",flexDirection:"row"}}><div style={{height:20,width:4,backgroundColor:"#2337C6",marginRight:4}}></div>Basic Job Information</div>
                            :""}
                                <Col key={ele?.id} span={ 8}  >

                                    <Form.Item  initialValue={datastored[ele?.name]} key={ele?.id} label={ele?.label} name={ele?.name}>
                                        {ele?.type == "dropdown" ?
                                            <Select placeholder={`Select ${ele?.label}`}>
                                                {ele?.value?.map(eles => (
                                                    <Select.Option value={eles}>{eles}</Select.Option>
                                                ))}

                                            </Select> : ele?.type === "date" ?
                                                <DatePicker  style={{ width: "100%" }} format="YYYY-MM-DD"/>
                                                : ele?.type === "dateDuration" ?
                                                    <RangePicker style={{ width: "100%" }} />
                                                    :ele?.type==="number"?
                                                    <InputNumber placeholder={`Enter ${ele?.label}`}    style={{width: '100%'}} />
                                                    
                                                    : <Input type={ele?.typeof} placeholder={`Enter ${ele?.label}`} />}

                                    </Form.Item>
                                </Col>
                              
                                {(index === 6 || index === 13) && <Divider style={{marginBottom:32}}/>}
                               
                            </>
                        ))}
                    </Row>
                    <Row>
                        <Col span={18}></Col>
                        <Col span={6}>
                            <Space>
                 <Button>Cancel</Button>
                 <Button htmlType="submit" type="primary">Save Data</Button>
                 </Space>
                 </Col>
                 </Row>
                </Form>
            </div>
        </Modal>
    )
}

export default JobOverviewEditModal