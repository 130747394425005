import React, { useState } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import {Select} from 'antd';
import './vacancyAnalysis.css'

//const COLORS = {colors};



const VacancyAnalysis = ({data,colors, totalCount, title, content}) => {

  const CustomTooltip = ({ active, payload }) => {
  
    if (active && payload && payload.length) {
      const { name, value , index} = payload[0].payload;
      const length = colors.length;
  
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: '#fff',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '8px'
          }}
        >
          <div style={{
                    width: '12px',
                    height: '12px',
                    borderRadius: '2px',
                    backgroundColor: colors[index%length],
                  }}>
          </div>
          <div style={{display: 'flex', flexDirection:'row', gap: '2px', marginRight: '10px', fontFamily: 'Roboto'}}>
              <div style={{ margin: 0, fontFamily: 'Roboto'}}>{name}</div>
              <div style={{color:'#616161', fontSize: '14px'}}>{Math.round(value/totalCount*100)}%</div>
          </div>
          <div style={{ margin: 0 , fontWeight: 700}}>{value}</div>
        </div>
      );
    }
  
    return null;
  };

  const onChange = (value) => {
    console.log(`selected ${value}`);
  };
  const onSearch = (value) => {
    console.log('search:', value);
  };


  return(
  <div className='vacancyanalysis-container'>
    <div className='vacancyanalysis-subcontainer'>
      <div>
        <div>{title}</div>
        <div>{content}</div>
    </div>
    <div>
        <Select
                    showSearch
                    placeholder="Select"
                    optionFilterProp="label"
                    onChange={onChange}
                    onSearch={onSearch}
                    defaultValue={"Job type"}
                    options={[
                      {
                        value: 'Department',
                        label: 'Department/Category',
                      },
                      {
                        value: 'Job type',
                        label: 'Job type',
                      },
                      {
                        value: 'Work arrangments',
                        label: 'Work arrangments',
                      },
                      {
                        value: 'Travel involvment',
                        label: 'Travel involvment',
                      },
                      {
                        value: 'Recruitment type',
                        label: 'Recruitment type',
                      },
                      {
                        value: 'Preferred candidates',
                        label: 'Preferred candidates',
                      },
                    ]}
                    className='vacancyanalysis-search'
        />
    </div>
    </div>
  <div className='labels' style={{alignItems: 'center', justifyContent:'space-between', width:'100%'}}>
    <div>
  <PieChart width={250} height={250}>
    <Pie
      data={data}
      cx="50%"
      cy="50%"
      innerRadius={55}
      outerRadius={95}
      fill="#000000"
      paddingAngle={0}
      dataKey="value"
    >
      {data.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
      ))}
    </Pie>
    <Tooltip content={CustomTooltip} />
    
   
  </PieChart>
  </div>
  {/* <div className="custom-legend">
        {data.map((entry, index) => (
          <div key={`legend-item-${index}`} className="legend-item">
            <span
              className="legend-color"
              style={{ backgroundColor: colors[index % colors.length] }}
            />
            <span className="legend-text">
              {entry.name}  {entry.value}
            </span>
          </div>
        ))}
      </div> */}
  <div style={{ width:'100%',fontFamily: 'Roboto', marginLeft:'24px'}}>
  {data.map((entry, index) => (
        <div style={{ display: 'flex', flexDirection: 'row', width:'100%', justifyContent:'space-between', marginBottom:'8px'}}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px', flex: '0 0 60%'  }}>
                <div style={{ width: '12px', height: '12px', backgroundColor: colors[index % colors.length], borderRadius: '2px' }}></div>
                <div style={{ fontFamily: 'Roboto' }}>{entry.name}</div>
            </div>
        
            <div style={{ fontWeight: '700', fontFamily: 'Roboto', flex: '0 0 20%', alignItems:'right'  }}>{entry.value}</div>
            <div style={{ color: '#616161', fontSize: '14px',  flex: '0 0 20%'  }}>{Math.round((entry.value / totalCount) * 100)}%</div>
      
        </div>
    ))}

  </div>
  </div>
  </div>
);
};

export default VacancyAnalysis;
