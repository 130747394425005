import React from "react";
import './styles.css'



const Dashboard= ()=>{


    return (
        <div className="dashboard-wrapper">

     <div className="dashboard-container">
        
       <div className="dashboard-heading"> Dashboard</div>
       <div className="dashboard-subheading">Resource Management</div>
        </div>
        </div>
    );
}

export default Dashboard;