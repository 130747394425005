import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer ,Cell,CustomLabel} from 'recharts';
import './vacancySummary.css'
import { useAnalyticsStore } from '../store/store';

// const data = [
//   { name: '18-25', Male: 75, Female: 80, maleCount:75, femaleCount:80 },
//   { name: '26-35', Male: 70, Female: 75, maleCount:70, femaleCount:75 },
//   { name: '36-45', Male: 70, Female: 80, maleCount:70, femaleCount:80 },
//   { name: '46-55', Male: 65, Female: 70, maleCount:65, femaleCount:70 },
//   { name: '56-65', Male: 70, Female: 75, maleCount:70, femaleCount:75 }
// ];
// const colors=["#B37FEB","#EFDBFF"];

const VacancySummary = ({type,title,content,colors,data}) => {
  const {analyticsData, setAnalyticsData} = useAnalyticsStore(state=>state);

    const yAxisTickFormatter = (value) => {
        return `${value}`;
      };
    const xAxisTickFormatter = (value)=>{
     
        return `${value}`;
      
    }

      const CustomTooltip = ({ active, payload }) => {
        console.log(active,"CUSTOMTOOLTIP CHECK")
        if (active && payload && payload.length) {
          const { name, Male, Female, maleCount, femaleCount } = payload[0].payload;
      
          return (
            <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc', width:'180px' }}>
              <p className="label" style={{ fontWeight: 'bold' }}>{`${name}`}</p> 
              

              <div style={{display: 'flex', flexDirection: 'row' ,justifyContent: 'space-between',alignItems:'center'}}>
              <div style={{display:'flex', alignItems:'center', gap:'4px'}} >
                <div
                  style={{
                    width: '12px',
                    height: '12px',
                    borderRadius: '2px',
                    backgroundColor: colors[0],
                  }}
                ></div>
                
                  
                    <div style={{fontFamily: 'Roboto'}}>Male</div>
                    <div style={{ color: '#616161', fontFamily: 'Roboto'}}>{Math.round(Male)}%</div>
                  </div>
                  <div style={{fontFamily: 'Roboto'}}>{maleCount}</div>
                </div>
             
              <div style={{display: 'flex', flexDirection: 'row' ,justifyContent: 'space-between',alignItems:'center'}}>
              <div style={{display:'flex', alignItems:'center', gap:'4px'}} >
                <div
                  style={{
                    width: '12px',
                    height: '12px',
                    borderRadius: '2px',
                    backgroundColor: colors[1],
                  }}
                ></div>
                
                  
                    <div style={{fontFamily: 'Roboto'}}>Female</div>
                    <div style={{ color: '#616161', fontFamily: 'Roboto'}}>{Math.round(Female)}%</div>
                  </div>
                  <div style={{fontFamily: 'Roboto'}}>{femaleCount}</div>
                </div>
              
            </div>
          );
        }
      
        return null;
      }; 
      
  return (
    <div className="chart-container">
      <div>
      <div>{title}</div>
      <div>{content}</div>
      </div>
      {type==="vacancysummary"?
    
      <div style={{display:'flex',flexDirection:'row', alignItems:'center',justifyContent:'space-between', width:'100%', borderBottom: '1px solid #E9E9E9', paddingBottom:'24px'}}>
        <div>
          <div style={{display:'flex',flexDirection:'row', alignItems:'center', gap:'4px'}}>
            <div style={{width:'14px', height:'14px', backgroundColor:"#B37FEB", borderRadius:'2px'}}></div>
            <div style={{fontSize:'24px', fontWeight:'700'}}>{analyticsData?.summary?.activeVacancyCount}</div>
          </div>
          <div>Total Active Vacancies</div>
        </div>
        <div>
          <div style={{display:'flex',flexDirection:'row', alignItems:'center', gap:'4px'}}>
            <div style={{width:'14px', height:'14px', backgroundColor:"#EFDBFF", borderRadius:'2px'}}></div>
            <div style={{fontSize:'24px', fontWeight:'700'}}>{analyticsData?.summary?.inactiveVacancyCount}</div>
          </div>
          <div>Total In-active Vacancies</div>
        </div>
        <div>
          <div style={{fontSize:'24px', fontWeight:'700'}}>{analyticsData?.summary?.ratio}</div>
          <div>Ratio of active to in-active</div>
        </div>
      </div>
      :null}
    <ResponsiveContainer width="100%" height={189} >
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 10, right: 10, left: -30, bottom: 0,
        }}
        barCategoryGap={0}
      >
        <CartesianGrid strokeDasharray="3 3" vertical={false}/>
        <XAxis 
          dataKey="name" 
          axisLine={false} 
          tickFormatter= {type === "vacancysummary" ? xAxisTickFormatter : null}   
          tick = {type!=="vacancysummary"?false:true}
          tickLine={false} 
          style={{fontFamily: 'Roboto', fontSize: '12px'}} 
        />
        <YAxis  axisLine={false} tickLine={false} tickFormatter={yAxisTickFormatter}   tickCount={3} style={{fontFamily: 'Roboto', fontSize: '12px'}}/>

        {/* <Tooltip content={CustomTooltip}/> */}
        
        {type==="vacancysummary"?<Bar dataKey="activeCount" fill={colors[0]} barSize={12}/>:
        type==="active"?
        <Bar dataKey="value" radius={[0, 0, 0, 0]} barSize={25}
        // label={<CustomLabel />}
        >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index]} />
            ))}
          </Bar>
          :
          <Bar dataKey="value" radius={[0, 0, 0, 0]}  barSize={25}
          // label={<CustomLabel />}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index]} />
            ))}
          </Bar>
          }
        {type==="vacancysummary"?<Bar dataKey="inactiveCount" fill={colors[1]} barSize={12}/>:null}
       
       
      </BarChart>

      {type!=="vacancysummary"?<div style={{ display: 'flex', flexDirection: 'column', fontSize: '14px',gap:'8px'}}>
  {data.map((item, index) => (
    <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center',justifyContent:'space-between' }}>
      <div style={{alignItems:'center',display:'flex',flexDirection:'row', gap:'8px'}}>
      <div style={{ width: '12px', height: '12px', borderRadius: '2px', backgroundColor: colors[index] }}></div>
      <div style={{ fontSize: '14px', fontFamily: 'Roboto' }}>{item?.name}</div>
      </div>
      <div>{item?.value}</div>
    </div>
  ))}
      </div>:null}
    </ResponsiveContainer>
 
    </div>
  );
};

export default VacancySummary;
