import React from "react";


const CandidateOverview = ()=>{

    return (
        <div>Recruitment Overview</div>
    );

}
export default CandidateOverview