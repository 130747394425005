import React from "react";

const CustomTitle = ({title,styles, titleStyles})=>{
    return (
        <div style={{display:'flex', flexDirection:'row', gap: '4px', alignItems:'center'}}>
                <div style={{
                    ...styles
                }}></div>
                <div style={{color:"#1f1f1f", fontFamily:' Figtree', fontSize:'16px',fontStyle:'normal', fontWeight: 600,lineHeight:'normal'}} >
                   {title}
                </div>
            </div>

    );


}

export default CustomTitle;