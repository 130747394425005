import React, { useState } from "react";
import JobDescription from "./jobDescription";
import CustomButton from "./customButton";
import JobIndustrySelector from "./jobIndustrySelector";
import Generate from "../../assets/generateIcon.svg"
import JobLevel from "./jobLevel";
import "./createJD.css"
import { useStore } from "./store/store.js";
import CustomModal from "./customModal.js";
import tipIcon from "../../assets/tip.svg";
import {message } from "antd";
import Header from "../../landing/header.js";
import design from "../../assets/backgrounddesign.svg"
import { useNavigate } from 'react-router-dom';
const CreateJD = () =>{
    const {titleJob, titleIndustry,isModalOpen,setIsModalOpen,jdData,setJdData,jobExperience,progress,setProgress} = useStore(state=>state)
    const navigate = useNavigate();
    const jobLevels = [
        { title: 'Entry Level', experience: '(0-2 years)' },
        { title: 'Junior Level', experience: '(2-5 years)' },
        { title: 'Mid Level', experience: '(5-8 years)' },
        { title: 'Senior Level', experience: '(8-12 years)' },
        { title: 'Manager Level', experience: '(12-15 years)' },
        { title: 'Principal Level', experience: '(15+ years)' },
        
      ];

    // const handleClick = () =>{
        //setIsModalOpen(true);
        const postData = async () => {
            if(titleJob===''){
                message.warning("Please input job title ")
                return;
            }
            // setIsLoading(true);
            setProgress(0)
            setIsModalOpen(true);
            const url = 'https://jd.brigosha.com/api/ai-task'; // Replace with your API URL
            const payload = {
                

              "role":titleJob,
              "must_have_skills":"",
              "good_to_have_skills": "",
              "years_of_experience": jobExperience,
              "degree_requirements": "",
              "industry":titleIndustry
            };
            
            try {
              const response = await fetch(url, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'ngrok-skip-browser-warning': 'anything',
                  Authorization: "",
                },
                body: JSON.stringify(payload),
              });
        
              const result = await response.json();
              setProgress(100)
              console.log("data",result?.response)
              setJdData(result?.response); // Assuming response returns data
            

            } catch (error) {
              console.error('Error:', error);
              setProgress(0)
              setIsModalOpen(false);
              message.error('Something went wrong!')
            } 
            // finally {
            //   setIsLoading(false);
            // }
          };
    // }
    const handleCloseModal = () =>{
        setIsModalOpen(false)
    }
    return(
    <div className="createJD-container" >
           
        <Header color={'#2337C6'} />
        <div className="job-description">
         <div class="flex-overlay-container">
         <img src={design} class="image2" alt="Second Image"/>
        </div>
        {<div  className="createJD-child" >
            <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}> 
                 <div className="heading-content">Create Job Description</div>
                  <div className="paragraph-content">AI-powered job descriptions at your fingertips—just click and go!</div>
            </div>
            <div style={{display:'flex', flexDirection:'row',gap:'1rem', alignItems:'center',justifyContent:'center', marginTop:'5rem',flexWrap:'wrap'}}>
                 <JobIndustrySelector/>
                 <CustomButton text="Generate" img={Generate} onClick={postData}/>
             </div>
             <div className="heading-content1" >Select experience level</div>  
             <div className="paragraph-content1" style={{display:'flex',flexDirection:'row', alignItems:'center',flexWrap:'wrap'}}>
                
        
                 {jobLevels.map((level,index)=>(
                  <>
                  {index!==0?<span className="divider"></span>:null}
                  <JobLevel key={index} title={level.title} expYears={level.experience}/>
                  </>
                     
                     
                 ))
               
                 }
               
             </div> 
           
             <div className="flex-overlay-footer">
                <img src={tipIcon} alt="tipIcon"/>
                <span>Tip :</span> Provide both job title and industry for more precise results.
            </div>
          
         </div>}

       
        </div>
         
             
          {isModalOpen && (<CustomModal show={isModalOpen} handleClose={handleCloseModal} />)}
    </div>
    );
}

export default CreateJD;