import React from 'react';
import { updateVacancyState, getVacancyRequests } from './Action/action';

const StatusCard = ({name, index,type,onClick}) => {


    
    

    const getBackgroundColor = (name,type) => {
        switch (name) {
            case "Draft":
                return "#F5F5F5";
            case "Pending":
                return "#FFF7E6";
            case "Activated":
                return type==="Responder"?"#52C41A":"#F6FFED";
            case "Denied":
                return type==="Responder"?"#FFFFFF":"#FFF1F0";
            case "Activate":
                return "#52C41A";
            case "Deny":
                return "#FFFFFF";
            default:
                return "#FFFFFF";
        }
    };

    const getTextColor = (name,type) => {
        switch (name) {
            case "Draft":
                return "#909090";
            case "Pending":
                return "#FA8C16";
            case "Activated":
                return type==="Responder"?"#fff":"#52C41A";
            case "Denied":
                return "#F5222D";
            case "Activate":
                return "#ffffff";
            case "Deny":
                return "#F5222D";
            default:
                return "#ffffff";
        }
    };

    return (
        <div style={{ 
            display: 'flex',  
            flexWrap: 'wrap',
            cursor: type==="Responder" && (name==='Activate'||name==='Deny')?'pointer':null,
            }}
            onClick={type === "Responder" && (name === 'Activate' || name === 'Deny') ? ()=>{onClick(index,name)} : null}
        >
            
                <div 
                    key={index} 
                    style={{
                        padding: '10px 20px',
                        backgroundColor: getBackgroundColor(name,type),
                        color: getTextColor(name,type),
                        borderRadius: type==="Responder"?'0px':'4px',
                        textAlign: 'center',
                        fontWeight: 'bold',
                        minWidth: type==="Responder"?'87px':'100px',
                        border: type==="Responder"||type==="Responder-edit" && name==="Deny" || type==="Responder" && name==="Denied"?`1px solid ${getTextColor(name,type)}`:'',
                    }}
                >
                    {type==="Responder-edit"?name:name?.toUpperCase()}
                </div>
 
        </div>
    );
};

export default StatusCard;
