import React from "react";
import "./customButton.css"

const CustomButton =({text, img,onClick})=>{

    return (
        <button className="button-container" onClick={onClick}><img src={img} alt="Icon" />{text}</button>
    );

}
export default CustomButton;