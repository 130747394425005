
import React, { useState,useEffect,useRef } from "react";
import { Input, Space } from 'antd';

import descArrow from "../../assets/descSort.svg"
import aescArrow from "../../assets/aescSort.svg"
import aescSelected from "../../assets/aescSelectedSort.svg"
import descSelected from "../../assets/descSelected.svg"
import filterIcon from "../../assets/filterIcon.svg"
import selectedFilterIcon from "../../assets/filterIconSelected.svg"
import no_data from '../../assets/icons/no-job.svg'
import './styles.css'
import Pagination from "../../pages/vacancies/customPagination";
import Divider from "../divider/Divider";
const { Search } = Input;

const Table=({columns,rowData,handlePageChange,handleItemsPerPageChange,loading,scrollMore})=>{

    return (
       
        <div class="table-fixed-column-outter">
        <div class="table-fixed-column-inner" style={{minHeight: rowData?'max-content':'80vh'}}>
        <table className="table custom-table">
            <thead className="table-thead">
                <tr  style={{height:'51px'}}>
                {columns?.map((column,index)=>(

                
                   column?.meta?.type=='sort'?
                    <SortCell handler={column?.handler} meta={column?.meta}/>
                    :
                    column?.meta?.type=='dropdown'?
                    <DropDownCell handler={column?.handler} meta={column?.meta}/>
                    :
                    column?.meta?.type=='default'?
                    <th className="table-headerCell"  style={{minWidth:column?.meta?.styles?.width,boxShadow: index==0 && scrollMore? '3px 5px 13px 0px rgba(0, 0, 0, 0.1)':'none'}}>
                     <div  style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                      <div>{column?.meta?.title}</div>
                      </div> 
                      </th>
                    :
                    <th className="table-headerCell" style={{width:column?.meta?.styles?.width,boxShadow: index==0 && scrollMore? '3px 5px 13px 0px rgba(0, 0, 0, 0.1)':'none'}}></th>
                   
                
             
                
                ))}
                </tr>
            </thead>
            <tbody>
            {

              !loading?
           
              rowData?
              <>
                {rowData?.map((data,index) => {
                    return <tr  style={{height:'61px'}}>
                {data?.map((item,i)=>(

                      <td className="table-cell" style={{boxShadow: i==0 && scrollMore? '3px 5px 13px 0px rgba(0, 0, 0, 0.1)':'none'}}  >
                       {item?.renderItem??'-'}
                      </td>
                     
              
                    ))}
                    </tr>
                })}</>
                :
                <>
                <tr>
                  <td></td>
                </tr>
                <tr >
                <td style={{width:'100%',height:'90%'}} className="no-table-data">

            
                 <img src={no_data }/>
                 <h3>Oops! Nothing matches your search.</h3>
                 <p>Try adjusting your search terms or use different keywords for better results.</p>
       
             
                </td></tr>
                </>
           
                :
                [...new Array(10)].map(e=>{ 

                  
                return <tr style={{height:'61px'}} >
                {
                  [...new Array(columns?.length??3)].map((e,i)=>{ 
                  return   <td className="table-cell " style={{boxShadow:i==0  && scrollMore? '3px 5px 13px 0px rgba(0, 0, 0, 0.1)':'none'}} >
                  <div className="animated-bg animated-bg-text" style={{width:'120px',height:'20px',borderRadius:'6px'}}></div>
                    </td>
                  })
                }
             
                </tr>
            
                })
               
                    
             
            

            }
         
                
            </tbody>
          
        </table>
        

     
        </div>
   
        </div>
     
    )
}
export default Table;



export  const SortCell=({meta,handler,index,scrollMore})=>{

    const [sort,setSort]=useState();

    return(
        <th className="table-headerCell"   style={{width:meta?.styles?.width,boxShadow: index==0 && scrollMore? '3px 5px 13px 0px rgba(0, 0, 0, 0.1)':'none'}}>
        <div  style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
          <div> {meta?.title}</div>
            {(sort==='ASC')?<div style={{display:'flex',flexDirection:'column'}}>
                <img src={aescSelected} onClick={()=>{
                handler();
                setSort();

              }} style={{cursor:'pointer'}}/>

                <img src={descArrow} onClick={()=>{
                  handler('DESC')
                  setSort('DESC');
                }} style={{cursor:'pointer'}}/>
            </div>:
            (sort==='DESC')?<div style={{display:'flex',flexDirection:'column'}}>
                <img src={aescArrow} onClick={()=>{
                handler('ASC');
                setSort('ASC');
              }} style={{cursor:'pointer'}}/>

                <img src={descSelected} onClick={()=>{
                  handler();
                  setSort();
                }} style={{cursor:'pointer'}}/>
            </div>
            :
            <div style={{display:'flex',flexDirection:'column'}}>
                <img src={aescArrow} onClick={()=>{
                handler('ASC');
                setSort('ASC');
              }} style={{cursor:'pointer'}}/>

                <img src={descArrow} onClick={()=>{
                  handler('DESC');
                  setSort('DESC');
                }} style={{cursor:'pointer'}}/>
            </div>
            }
          </div>
       
      </th>
    )

}

export const DropDownCell=({meta,handler,index,scrollMore})=>{
    const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Tracks dropdown open state
    const [searchTerm, setSearchTerm] = useState(''); // Search term for filtering options
    const [selectedOptions, setSelectedOptions] = useState([]); // Track selected options
    const dropdownRef = useRef(null); // Ref for dropdown to detect clicks outside
    const buttonRef = useRef(null);
    const toggleDropdown = () => {
      setIsDropdownOpen(!isDropdownOpen);
    };
  
    // Closes dropdown if clicked outside of it
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownRef.current &&
            !dropdownRef.current.contains(event.target) && // Click is outside dropdown
            buttonRef.current &&
            !buttonRef.current.contains(event.target) ) {

          setIsDropdownOpen(false);
        }else if(isDropdownOpen){
            setIsDropdownOpen(false);
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);
  
    const handleOptionSelect = (option) => {
      if (meta.isMultiSelect) {
        // Toggle selection in multi-select mode
        if (selectedOptions.includes(option)) {
          setSelectedOptions(selectedOptions.filter((o) => o !== option));
        } else {
          setSelectedOptions([...selectedOptions, option]);
        }
      } else {
        // Single select: only one option can be selected
        setSelectedOptions([option]);
      }
    };
  
    const handleSearch = (term) => {
      setSearchTerm(term);
    };
  
    const handleReset = () => {
      setSelectedOptions([]); // Reset selections
     
    };
  
    const handleSubmit = () => {
     
      setIsDropdownOpen(false); // Close the dropdown after submission
      handler(selectedOptions)
    };
  
    const filteredOptions = meta.options.filter((option) =>
      option?.key?.toLowerCase()?.includes(searchTerm?.toLowerCase())
    );
  
    return (
      <th className="table-headerCell" style={{width:meta?.styles?.width,boxShadow: index==0 && scrollMore? '3px 5px 13px 0px rgba(0, 0, 0, 0.1)':'none'}}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between',position:'relative' }}>
          <div>{meta?.title}</div>
          <img
            src={selectedOptions.length>0 ? selectedFilterIcon : filterIcon}
            alt="filterIcon"
            onClick={toggleDropdown} // Toggles dropdown on button click
            ref={buttonRef}
          />
          {isDropdownOpen && (
            <div className="table-dropdown" ref={dropdownRef}>
            <div className="search-input"> 
            {meta.options.length > 5 && (
              <>
            <Search
                  placeholder="Search"
               defaultValue={searchTerm}
                  onChange={(e)=>{
                    console.log("drop search",e.target.value,searchTerm)
                    handleSearch(e.target.value)
                  }}
                  onSearch={(value)=>{
                    handleSearch(value)
                  }}
              
                />

          <Divider/>
          </>
                )}
            </div>
       
            {meta?.selectAll &&
                  <div>
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedOptions(meta.options?.map(ele=>ele?.value)); // Select all
                        } else {
                          setSelectedOptions([]); // Deselect all
                        }
                      }}
                      checked={selectedOptions.length === meta.options.length}
                    />
                    <label>Select All</label>
                  </div>
                
                }
             
              {meta.isMultiSelect ? (
                <div className="multi-select-dropdown">
                  <div className="options-list">
                 { filteredOptions?.length>0?
                    filteredOptions.map((option) => (
                      <div key={option.value}>
                        <input
                          type="checkbox"
                          checked={selectedOptions.includes(option.value)}
                          onChange={() => handleOptionSelect(option.value)}
                        />
                        <label>{option.key}</label>
                      </div>
                    )):
                    <>nodata</>}
                  </div>
                  <Divider/>
                  <div className="actions">
                  <button onClick={handleReset}>Reset</button>
                  <button onClick={handleSubmit}>OK</button>
                  </div>
              
                </div>
              ) : 
              (
                <div className="single-select-dropdown">
                <div className="options-list">
                { filteredOptions?.length>0?
                filteredOptions.map((option) => (
                <div
                key={option.value}
                className={`option-item ${selectedOptions[0] === option.value ? 'selected' : ''}`}
                onClick={() => handleOptionSelect(option.value)}
                >
                {option.key}
                </div>
                )):<>no data</>}
                </div>
                <Divider/>
                <div className="actions">
                <button onClick={handleReset}>Reset</button>
                <button onClick={handleSubmit}>OK</button>
                </div>
              
                </div>

              )}
            </div>
          )}
        </div>
      </th>
    );
}