import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Button, Checkbox } from "antd";
import React, { useState } from "react";
import Divider from "../../../components/divider/Divider";
import { useVacancyStore } from "../store/store";
const FilterSiderbar=({loading})=>{
    const[expandedFilters,setExpandedFilters]=useState([]);
    const {nFilters,setnFilters, jobsResponse,setJobsResponse,jobFilters} = useVacancyStore(state=>state);
    const [expandedOptions, setExpandedOptions] = useState({});

  const toggleShowMore = (filterKey) => {
    setExpandedOptions(prevState => ({
      ...prevState,
      [filterKey]: !prevState[filterKey]
    }));
  };
  const toggleShowAll = (filterKey) => {
    setExpandedFilters(prevState => ({
      ...prevState,
      [filterKey]: !prevState[filterKey]
    }));
  };

    
    // [
    //     {
    //         title:"Recruitment type",
    //         key:"recruitmentType",
    //         options:[{
    //             key:"Lateral recruitment",
    //             value:"Lateral recruitment"
    //         },
    //         {

    //         key:"Campus recruitment",
    //         value:"Campus recruitment"
    //         }]

    //     },
      
   
    // ]

    const handleSelectFilter=(filterKey,filterOption)=>{
      if(nFilters?.[filterKey]?.includes(filterOption)){

        setnFilters({
          ...nFilters,
          [filterKey]:nFilters?.[filterKey]?.split(",")?.filter(item => item !== filterOption).join(",")
        })

      }else{
      setnFilters({
        ...nFilters,
        [filterKey]:nFilters?.[filterKey]?nFilters?.[filterKey]+','+filterOption:filterOption
      })
    }
    }

    const handleClear=()=>{
      if(!loading){
      setnFilters();
      setExpandedFilters([])
      setExpandedOptions({})
      }
    }





    return (
    <div className="filter-container">
    
     <div className="filter-header">
        <div className="filter-heading">Filters</div>
        <div className="filter-clearall" onClick={handleClear}>Clear all</div>
     </div>
     {jobFilters?
     jobFilters?.map((filter,filterIndex)=>{
        return <>
        <Divider/>
        <div className="filter-type">
     <div className="filter-type-header">
     <div className="filter-heading">{filter?.title}</div>
     <div>
    { !expandedFilters[filter.title]?
        <UpOutlined  onClick={() => toggleShowAll(filter.title)} />
        : <DownOutlined  onClick={() => toggleShowAll(filter.title)}/>}
     </div>
     </div>
    {!expandedFilters[filter.title]?   
     <div className="filter-type-options" key={filterIndex}>

    
      
          {filter?.options?.slice(0, expandedOptions[filter.title] ? filter.options?.length : 6)?.map((option, optionIndex) => (
            <div key={optionIndex} className="filter-type-option" >
              <Checkbox  onClick={()=>{handleSelectFilter(filter?.key,option?.value)}} checked={nFilters?.[filter?.key]?.includes(option?.value)}/>
              <div>{option.key}</div>
            </div>
          ))}

          {/* Show "+n more" if there are more than 6 options and it's collapsed */}
          {filter?.options?.length > 6 && !expandedOptions[filter.title] && (
            <div className="filter-type-option" onClick={() => toggleShowMore(filter.title)}>
              <span>+{filter.options.length - 6} more</span>
            </div>
          )}

          {/* Show "Show less" if the filter is expanded */}
          {expandedOptions[filter.title] && (
            <div className="filter-type-option" onClick={() => toggleShowMore(filter.title)}>
              <span>Show less</span>
            </div>
          )}
        </div>


     :null}
     </div>
        </>
     })
     : 
     <>
     {/* !loading?
     <>
     <Divider/>
     <div className="no-filter">

     </div>
     </>
    : */}
    
    <Divider/>
      <div className="filter-type">
      {[...new Array(5)].map(e=>{
        return <>
     <div className="filter-type-header">
     <h3 className="filter-heading  animated-bg animated-bg-text" style={{width:'90%',height:'15px'}}>   &nbsp;</h3>
     <div className=" animated-bg animated-bg-text" style={{width:'15px',height:'15px',borderRadius:'4px'}} >
     &nbsp;
  
     </div>
     </div>
      {[...new Array(4)].map(e=>{
  
   return  <div className="filter-type-options"  style={{display:'flex',flexDirection:'row',alignItems:'center',}}>

<div  className="filter-type-option animated-bg animated-bg-text"  style={{width:'10px',height:'10px',borderRadius:'3px'}} >
&nbsp;
</div>
<div className=" animated-bg animated-bg-text" style={{width:'70%',height:'10px'}}></div>

</div>
       })}
       <div style={{height:'20px'}}></div>
       </>
     
      })}
     </div>

    </>
    }
    
    <div style={{height:'60px'}}></div>
    </div>
    )
}
   
    export default FilterSiderbar;