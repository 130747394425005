
import Header from './header';
import './homeStyles.css';
import Lottie from "lottie-react";
import brainAnimi from '../animations/Brain.json'
import particleTop from '../assets/particle-top.svg'
import particleBottom from '../assets/particle-bottom.svg'
import Footer from './footer';
function Home() {
  return (
    <div className="landing_home">
    <div className='landing_particle-top'>
        <img src={particleTop}></img>
    </div>
    <Header/>
    <main className='landing_main'>
    <section className='landing_left-section'>
    <Lottie animationData={brainAnimi} loop={false} />
    </section>
    <section className='landing_right-section'>
     <div className='landing_top-lines'>
     <span className='landing_line1'></span>
     <span className='landing_line2'></span>
     </div>
     <div className='landing_content'>
      <h1>let AI work</h1>
      <span>whiteink.ai simplifies decision making and transforms intra-company communication.</span>
     </div>
     <div className='landing_bottom-lines'>
     <span className='landing_line3'></span>
     <span className='landing_line4'></span>
     <span className='landing_line5'></span>
     </div>

    </section>

    </main>
    <div className='landing_particle-bottom'>
    <img src={particleBottom}></img>
    </div>
    <Footer/>

    </div>
  );
}

export default Home;
