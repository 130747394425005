import React, { useState } from 'react';
import { Carousel, Radio } from 'antd';
import "./customCarousel.css"
import CustomBarChart from './customBarChart';

const contentStyle = {
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
  marginTop: '80px',

};

const CustomCarousel = ({slides}) => {
  const [dotPosition, setDotPosition] = useState('top');



  return (
    <>
      <Carousel dotPosition={dotPosition}>
      {slides.map((slide, index) => (
      <div key={index} >
        <div className='slidecard-container'>
            {slide}
        </div>
      </div>
    ))}
        
      </Carousel>
      
       
    </>
  );
};

export default CustomCarousel;
