import React from "react";
import './styles.css'
import Divider from "../../../components/divider/Divider";
import StatusBar from "./StatusBar";
import { Link, useNavigate } from "react-router-dom";
import location_mark from '../../../assets/icons/location-mark.svg'
import experince from '../../../assets/icons/experince.svg'
import { Dropdown, Tooltip } from "antd";
import { CheckOutlined, MoreOutlined } from "@ant-design/icons";
import { updateVacancyRequests } from "../Action/action";
export  const statusBtnColor=(status)=>{
     
  switch(status){
      case "Closed":return ["#FFF1F0","#F5222D"];
      case "On-hold":return ["#FFF7E6","#FA8C16"];
      default: return ["#E9E9E9","#7A7A7A"];

  }
}

export function timeAgo(date) {
  const now = new Date();
  const givenDate = new Date(date);
  const diffInSeconds = Math.floor((now - givenDate) / 1000);

  if (diffInSeconds < 60) {
    return `${diffInSeconds} second${diffInSeconds === 1 ? '' : 's'} ago`;
  }

  const diffInMinutes = Math.floor(diffInSeconds / 60);
  if (diffInMinutes < 60) {
    return `${diffInMinutes} minute${diffInMinutes === 1 ? '' : 's'} ago`;
  }

  const diffInHours = Math.floor(diffInMinutes / 60);
  if (diffInHours < 24) {
    return `${diffInHours} hour${diffInHours === 1 ? '' : 's'} ago`;
  }

  const diffInDays = Math.floor(diffInHours / 24);
  if (diffInDays < 30) {
    return `${diffInDays} day${diffInDays === 1 ? '' : 's'} ago`;
  }

  const diffInMonths = Math.floor(diffInDays / 30);
  if (diffInMonths < 12) {
    return `${diffInMonths} month${diffInMonths === 1 ? '' : 's'} ago`;
  }

  const diffInYears = Math.floor(diffInMonths / 12);
  return `${diffInYears} year${diffInYears === 1 ? '' : 's'} ago`;

  
}

export const StatusActions=({data,getAllJobs})=>{

  const updateStatus=(status)=>{
     
    let params={status, jobId:data?.id};
    updateVacancyRequests(params,()=>{
      getAllJobs();
    })
  }
  
  const statusList=[
    {
        key:'Active',
        label:<>Active &nbsp; {data?.status=='Active'?<CheckOutlined style={{color:'#1890FF'}}/>:null}</>
    },
    {
        key:'On-hold',
      
        label: <>On-hold  &nbsp; {data?.status=='On-hold'?<CheckOutlined style={{color:'#1890FF'}}/>:null}</>
          
      },
    {
        key:'Closed',
       
        label: <>Close  &nbsp; {data?.status=='Closed'?<CheckOutlined style={{color:'#1890FF'}}/>:null}</>
    }
  
    ]
 return ( <div 
  onClick={(e) => e.stopPropagation()} >
      <Dropdown
      menu={{
          items:statusList,
          defaultSelectedKeys: [data?.status],
      onClick:({key,domEvent})=>{
     
        console.log("docm",domEvent);
        domEvent.stopPropagation();
       updateStatus(key);
      }
      }}
      trigger={['click']}
      placement="bottomRight"
      style={{padding:'0'}}
 
  
    >
      <MoreOutlined  className="more-btn"/>
      </Dropdown>
      </div>);
}


const JobCard=({ data,status,getAllJobs})=>{
 

// utils/timeAgo.js
const navigate = useNavigate(); 


return (
    <div  onClick={(e)=>{
      e.stopPropagation();
      navigate(`/vacancies/${data?.id}`)
    }}   className="jobcard-container" >

        <div className="jobcard-header">
        <div>
        <Tooltip placement="top" title={data?.jobTitle}>
        <h3 className="jobcard-title">
        
        {data?.jobTitle??'-'}</h3>
        </Tooltip>
        <span className="jobcard-field">Software Development</span>
        </div>
        {/* {status=='inactive'?
            <button className="inactive-status" style={{background:statusBtnColor(data?.status)?.[0],color:statusBtnColor(data?.status)?.[1]}}>{data?.status?.toUpperCase()??'NA'}</button>
        :null} */}  
         <StatusActions data={data} getAllJobs={getAllJobs}/>
        </div>
        
       
        <div className="jobcard-spec">
        <div className="jobcard-exp">
            <img src={ experince}/>
            {data?.minExperience &&data?.maxExperience?
            <div>{data?.minExperience}-{data?.maxExperience} years</div>:
            <div>-</div>}
        </div>
        <div className="jobcard-location">
            <img src={ location_mark}/>
            <div>{data?.jobLocation??'-'}</div>
        </div>
        </div>
       
        <Divider/>
   
     
        <div className="jobcard-info">
            <div>0 Candidates</div>
            <div className="jobcard-info-dot"></div>
            <div>0/1 Recruited</div>
        </div>
        <div className="jobcard-status">
            <StatusBar percent={data?.percentage??0} 
            bars={data?.template?.stage?.map(e=>e?.stageKey)}
            stage={data?.stage}
            />
            {/* <div className="jobcard-status-info">
            <div className="jobcard-status-name">
            <div></div>
            <div>Vacancy Creation</div>
            </div>
            <div className="jobcard-status-percent"> 5% </div>
               
            </div> */}

        </div>
        <div className="jobcard-date">
            Created <span>{timeAgo(data?.createdAt)}</span>
        </div>


    </div>

);
}
export default JobCard;