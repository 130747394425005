import React, { useState } from 'react';
import { Button, Drawer,Popover } from 'antd';
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Input, Row, Col } from "antd";
import Delete from "../../../../assets/deleteIcon.svg"
import { useVacancyStore } from '../../store/store';
import Add from "../../../../assets/smallAdd.svg"
import Grab from "../../../../assets/grab.svg"
import DownArrow from "../../../../assets/blueDownArrow.svg"
import styles from "./recruit.module.css"
import { updateJobTemplate } from '../../Action/action';
const ManagementTask= ({openDrawer,onClose,data}) => {
  const {defaultTemplate,updateDefaultTemplate} = useVacancyStore(state=>state);
     const {templateDropdown} = useVacancyStore(state=>state);
    const [items, setItems] = useState(data?.templateStages);
    const [open,setOpen]=useState(false)
    const [tempName,setTempName]=useState(data?.templateName)

      const handleDragStart = (e, index) => {
        e.dataTransfer.setData("dragIndex", index);
     
        e.currentTarget.style.opacity = "0.5";
      };
    
      const handleDragEnd = (e) => {
        // Reset the opacity when drag ends
        e.currentTarget.style.opacity = "1";
      };
    
      const handleDragOver = (e) => {
        e.preventDefault(); // Allow dropping by preventing default behavior
      };
    
      const handleDrop = (e, dropIndex) => {
        const dragIndex = e.dataTransfer.getData("dragIndex");
        if (dragIndex === dropIndex) return;
    
        const updatedItems = [...items];
        const draggedItem = updatedItems.splice(dragIndex, 1)[0];
        updatedItems.splice(dropIndex, 0, draggedItem);
    
        setItems(updatedItems);
      };
    
      const handleInputChange = (index, field, value) => {
        const newItems = [...items];
        newItems[index][field] = value;
        setItems(newItems);
      };
      const hide = () => {
        setOpen(false);
      };
  
  return (
    <>
     
      <Drawer title={(<div style={{display:"flex",justifyContent:"space-between"}}><div style={{padding:4}}>Manage</div><div><Button onClick={onClose}>Cancel</Button> <Button type="primary" onClick={()=>{
        const params={
          templateName:tempName,
          templateId:data?.templateType==="Custom"?data?.templateId:undefined,
          jobId:window.location.pathname.split("/vacancies/")[1],
          templateStages:items
        }
        
        
        
        updateJobTemplate(params,(res,error) =>{
         if(res){
          let array=[...defaultTemplate]
        let index=array.findIndex(ele=>ele?.templateId===data?.templateId)
        array[index].templateStages=items
        updateDefaultTemplate(array)
        onClose()
         }
          console.log("res",res,error);
        })}}>Save</Button></div></div>)} onClose={onClose} open={openDrawer} width={740}>
      <div style={{ padding: "16px", backgroundColor: "white", borderRadius: 4 }}>
        <div style={{paddingBottom:20,}}>
        <div style={{marginBottom:8}}>Name</div>
        <input onChange={(e) =>setTempName(e.target.value)} defaultValue={tempName} style={{width:"98%",height:"30px"}}/>
        </div>
      {items?.map((item, index) => (
        <div
          key={item.index}
          draggable
          onDragStart={(e) => handleDragStart(e, index)}
          onDragEnd={handleDragEnd}
          onDragOver={(e) => handleDragOver(e)}
          onDrop={(e) => handleDrop(e, index)}
          className={styles.dragItems}
        >
          <Row gutter={16} style={{ width: "100%" }}>
          <Col span={2}>
              <Button type="link" >
                <img src={Grab}/>
              </Button>
            </Col>
            <Col span={8}>
           
              <Input
                placeholder="Name"
                value={item.stageName}
                onChange={(e) => handleInputChange(index, "name", e.target.value)}
              />
            </Col>
            <Col span={12}>
              <Input
                placeholder="Value"
                value={item.stageDescription}
                onChange={(e) => handleInputChange(index, "value", e.target.value)}
              />
            </Col>
            <Col span={2}>
              <Button type="link" danger onClick={() =>{
                let data=items.filter(ele =>ele?.stageId!=item?.stageId)
                setItems(data)
              }}>
                <img src={Delete}/>
              </Button>
            </Col>
          </Row>
          
        </div>
   
      ))}
      <div onClick={() =>setOpen(true)} style={{display:"flex",flexDirection:"row",margin:8,alignItems:"center",justifyContent:"center"}}>
    
          <img src={Add} height={"50%"}  /> 
        <div style={{color:"#1890FF"}}>Add stage {" "}</div>
        <img src={DownArrow} height={"20%"}/>
        </div>
    </div>
    <Popover
      content={<>
      <a onClick={hide}>Close</a>
      {templateDropdown?.map(ele =>{
        return(
        <div onClick={() =>{
          const newItems = [...(items ||[]),ele];
          console.log("new",items,ele)
          setItems(newItems)
        }} style={{margin:8,cursor:"pointer"}}>{ele?.stageName}</div>
        )
      })}</>}
      title=""
      trigger="click"
      open={open}
       onOpenChange={(newOpen) =>    setOpen(newOpen)}
    >
      
    </Popover>
      </Drawer>
    </>
  );
};

export default ManagementTask;






